<script setup lang="ts">
import { currentUserQuery } from "@/api/users.endpoints";
import { queryClient } from "@/queryClient";

definePage({
	beforeEnter: async () => {
		const me = await queryClient.ensureQueryData(currentUserQuery);
		if (!me.benefits.find((benefit) => benefit.key === "ADMIN")) {
			throw new Error(
				"Restricted access. You must have the ADMIN benefit to access this page.",
			);
		}
	},
});
</script>

<template>
	<RouterView />
</template>
