import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M5 17.5a.8.8 0 0 1-.6-.24.8.8 0 0 1-.23-.6v-.83H2.5a1.6 1.6 0 0 1-1.18-.49 1.6 1.6 0 0 1-.49-1.17V5q0-.69.5-1.18a1.6 1.6 0 0 1 1.17-.49h15q.69 0 1.18.5.48.48.49 1.17v9.17q0 .68-.5 1.17a1.6 1.6 0 0 1-1.17.5h-1.67v.83a.8.8 0 0 1-.24.59.8.8 0 0 1-.59.24zm-.83-5h11.66l-3.75-5-2.91 3.75-2.09-2.5z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }