<script lang="ts">
import { cva } from "class-variance-authority";

export const buttonVariants = cva(
	"flex items-center justify-center whitespace-nowrap font-medium outline-none ring-offset-2 ring-offset-stone-900 transition-[background-color,color,filter,transform,border-color] ease-out-quad focus-visible:ring-2 active:scale-[0.975] disabled:opacity-50",
	{
		variants: {
			variant: {
				primary:
					"bg-green-500 shadow-[0px_-1px_0px_0px_rgba(0,0,0,0.2)_inset,0px_1px_0px_0px_rgba(255,255,255,0.1)_inset] ring-green-200/75 hover:brightness-[1.15]",
				secondary:
					"bg-white/[.08] text-white/75 ring-stone-400 ring-offset-0 backdrop-blur-2xl hover:bg-white/[.12] disabled:opacity-50",
				ghost:
					"bg-transparent text-white/75 ring-white/25 ring-offset-0 hover:bg-stone-50/10",
				polar:
					"bg-stone-50 font-semibold text-stone-950 shadow-[0px_-1px_0px_1px_rgba(0,0,0,0.2)_inset] ring-stone-50 hover:bg-white",
				outline_white:
					"border border-white/20 bg-white/0 text-stone-50 ring-white/20 backdrop-blur-sm hover:border-transparent hover:bg-white/15 active:border-transparent active:bg-white/15",
				outline_green:
					"border border-green-200/50 text-green-200 ring-green-200/25 hover:border-transparent hover:bg-green-400/15",
				warning:
					"bg-orange-500/15 text-orange-400 shadow-[0px_1px_0px_0px_rgba(255,172,146,0.06)_inset] ring-orange-500/50 hover:bg-orange-500/20",
				destructive:
					"bg-red-500 text-white shadow-[0px_-1px_0px_0px_rgba(0,0,0,0.2)_inset,0px_1px_0px_0px_rgba(255,255,255,0.1)_inset] ring-[#F46D63] hover:bg-[#E23C3C]",
			},
			size: {
				xs: "h-8 gap-1.5 rounded-md px-2.5 text-sm",
				sm: "h-9 gap-1.5 rounded-md px-3 text-sm",
				md: "h-10 gap-1.5 rounded-md px-[14px] text-sm",
				lg: "h-11 gap-2 rounded-lg px-4 text-base",
				xl: "h-12 gap-2 rounded-lg px-[18px] text-base",
				"2xl": "h-14 gap-3 rounded-lg px-5 text-lg",
			},
		},
		defaultVariants: {
			variant: "primary",
			size: "md",
		},
	},
);
</script>

<script setup lang="ts">
import { twMerge, type ClassNameValue } from "tailwind-merge";
import { Primitive, type PrimitiveProps, useForwardExpose } from "radix-vue";

const props = defineProps<
	PrimitiveProps & {
		class?: ClassNameValue;
		variant?: NonNullable<Parameters<typeof buttonVariants>[0]>["variant"];
		size?: NonNullable<Parameters<typeof buttonVariants>[0]>["size"];
	}
>();

const { forwardRef } = useForwardExpose();
</script>

<template>
	<Primitive
		:as="props.as || 'button'"
		:as-child="props.asChild"
		:class="twMerge(buttonVariants({ variant, size }), props.class)"
		:ref="forwardRef"
	>
		<slot />
	</Primitive>
</template>
