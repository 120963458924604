import { queryOptions, useMutation, useQueryClient } from "@tanstack/vue-query";
import type {
	ManagedServer,
	MyServers,
	Server,
	ServerTransfer,
	ServersPagingObject,
} from "./servers.types";
import { FetchError, createURLSearchParams } from "@/utils";
import { currentUserQuery, userQuery } from "./users.endpoints";
import { getUserBenefit } from "./users.utils";

export const myServersQuery = queryOptions({
	queryKey: ["api", "users", "tokens", "list"],
	queryFn: async ({ signal }): Promise<MyServers> => {
		const res = await fetch("/api/users/tokens", { signal });
		if (!res.ok) {
			throw new FetchError(res);
		}
		const { data: servers } = (await res.json()) as { data: MyServers };
		return servers;
	},
});

export const ownedServerQuery = (serverId: string) =>
	queryOptions({
		queryKey: ["api", "users", "tokens", "details", serverId],
		queryFn: async ({ signal }) => {
			const res = await fetch(`/api/users/tokens/${serverId}`, {
				signal,
			});
			if (!res.ok) {
				throw new FetchError(res);
			}
			const { data: server } = (await res.json()) as { data: Server };
			return server;
		},
	});

export function useCreateServerMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (opts: { name: string }) => {
			const res = await fetch("/api/users/tokens", {
				method: "POST",
				body: JSON.stringify(opts),
				headers: { "Content-Type": "application/json" },
			});

			if (!res.ok) {
				throw new FetchError(res);
			}

			const { data: server } = (await res.json()) as { data: Server };
			return server;
		},
		onSuccess: async (createdServer) => {
			queryClient.setQueryData(myServersQuery.queryKey, (servers) => {
				if (!servers) return servers;
				return { ...servers, owned: [...servers.owned, createdServer] };
			});
			queryClient.setQueryData(
				ownedServerQuery(createdServer.public_id).queryKey,
				createdServer,
			);

			const me = await queryClient.ensureQueryData(currentUserQuery);
			const adminBenefit = getUserBenefit(me, "ADMIN");
			if (adminBenefit) {
				queryClient.resetQueries(userQuery(me.id));
				queryClient.resetQueries(supervisedServersQuery());
			}
		},
	});
}

export interface CreateServerErrorObject {
	statusCode: number;
	errors: string[];
	businessStatus: "NAME_ALREADY_EXISTS" | "VANITY_URL_ALREADY_EXISTS";
	isBusiness: boolean;
}

export interface UpdateOwnedServerBody {
	name?: string;
	vanity_url?: string;
	language?: string | null;
	website?: string | null;
	game_mode?: string | null;
	description?: string | null;
	tags?: string[];
	visible?: boolean;
	visible_in_skin?: boolean;
	group_id?: string | null;
}

export function useUpdateOwnedServerMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (opts: {
			params: {
				serverId: string;
			};
			body: UpdateOwnedServerBody;
		}) => {
			const res = await fetch(`/api/users/tokens/${opts.params.serverId}`, {
				method: "PATCH",
				body: JSON.stringify(opts.body),
				headers: { "Content-Type": "application/json" },
			});

			if (!res.ok) {
				throw new FetchError(res);
			}

			const { data: server } = (await res.json()) as { data: Server };
			return server;
		},
		onSuccess: async (updatedServer) => {
			queryClient.setQueryData(
				ownedServerQuery(updatedServer.public_id).queryKey,
				updatedServer,
			);
			queryClient.setQueryData(myServersQuery.queryKey, (servers) => {
				if (!servers) return servers;
				return {
					...servers,
					owned: servers.owned.map((server) =>
						server.public_id === updatedServer.public_id
							? updatedServer
							: server,
					),
				};
			});

			const me = await queryClient.ensureQueryData(currentUserQuery);
			const adminBenefit = getUserBenefit(me, "ADMIN");

			if (adminBenefit) {
				queryClient.resetQueries(
					supervisedServerQuery(updatedServer.public_id),
				);
				queryClient.resetQueries(userQuery(me.id));
				queryClient.resetQueries(supervisedServersQuery());
			}
		},
	});
}

export function useReissueOwnedServerTokenMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (params: { serverId: string }) => {
			const res = await fetch(`/api/users/tokens/${params.serverId}/reissue`, {
				method: "PATCH",
			});

			if (!res.ok) {
				throw new FetchError(res);
			}

			const { data: server } = (await res.json()) as { data: Server };
			return server;
		},
		onSuccess: async (updatedServer) => {
			queryClient.setQueryData(
				ownedServerQuery(updatedServer.public_id).queryKey,
				updatedServer,
			);
			queryClient.setQueryData(myServersQuery.queryKey, (servers) => {
				if (!servers) return servers;
				return {
					...servers,
					owned: servers.owned.map((server) =>
						server.public_id === updatedServer.public_id
							? updatedServer
							: server,
					),
				};
			});

			const me = await queryClient.ensureQueryData(currentUserQuery);
			const adminBenefit = getUserBenefit(me, "ADMIN");

			if (adminBenefit) {
				queryClient.resetQueries(
					supervisedServerQuery(updatedServer.public_id),
				);
				queryClient.resetQueries(userQuery(me.id));
				queryClient.resetQueries(supervisedServersQuery());
			}
		},
	});
}

export function useResetOwnedServerIPMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (params: { serverId: string }) => {
			const res = await fetch(`/api/users/tokens/${params.serverId}/reset-ip`, {
				method: "PATCH",
			});

			if (!res.ok) {
				throw new FetchError(res);
			}

			const { data: server } = (await res.json()) as { data: Server };
			return server;
		},
		onSuccess: async (updatedServer) => {
			queryClient.setQueryData(
				ownedServerQuery(updatedServer.public_id).queryKey,
				updatedServer,
			);
			queryClient.setQueryData(myServersQuery.queryKey, (servers) => {
				if (!servers) return servers;
				return {
					...servers,
					owned: servers.owned.map((server) =>
						server.public_id === updatedServer.public_id
							? updatedServer
							: server,
					),
				};
			});

			const me = await queryClient.ensureQueryData(currentUserQuery);
			const adminBenefit = getUserBenefit(me, "ADMIN");

			if (adminBenefit) {
				queryClient.resetQueries(
					supervisedServerQuery(updatedServer.public_id),
				);
				queryClient.resetQueries(userQuery(me.id));
				queryClient.resetQueries(supervisedServersQuery());
			}
		},
	});
}

export function useDeleteOwnedServerMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (params: { serverId: string }) => {
			const res = await fetch(`/api/users/tokens/${params.serverId}`, {
				method: "DELETE",
			});

			if (!res.ok) {
				throw new FetchError(res);
			}
		},
		onSuccess: async (_, { serverId }) => {
			queryClient.removeQueries(ownedServerQuery(serverId));
			queryClient.setQueryData(myServersQuery.queryKey, (servers) => {
				if (!servers) return servers;
				return {
					...servers,
					owned: servers.owned.filter(
						(server) => server.public_id !== serverId,
					),
				};
			});

			const me = await queryClient.ensureQueryData(currentUserQuery);
			const adminBenefit = getUserBenefit(me, "ADMIN");
			if (adminBenefit) {
				queryClient.removeQueries(supervisedServerQuery(serverId));
				queryClient.resetQueries(userQuery(me.id));
				queryClient.resetQueries(supervisedServersQuery());
			}
		},
	});
}

export const serverLanguagesQuery = queryOptions({
	queryKey: ["api", "users", "tokens", "availableLanguages"],
	queryFn: async ({ signal }) => {
		const res = await fetch("/api/users/tokens/availableLanguages", { signal });
		if (!res.ok) {
			throw new FetchError(res);
		}
		const { data: languages } = (await res.json()) as {
			data: Record<string, string>;
		};
		return languages;
	},
});

export const managedServerQuery = (ownerId: string, serverId: string) =>
	queryOptions({
		queryKey: ["api", "users", "management", ownerId, "tokens", serverId],
		queryFn: async ({ signal }) => {
			const res = await fetch(
				`/api/users/management/${ownerId}/tokens/${serverId}`,
				{
					signal,
				},
			);
			if (!res.ok) {
				throw new FetchError(res);
			}
			const { data: server } = (await res.json()) as { data: ManagedServer };
			return server;
		},
	});

export interface UpdateManagedServerBody {
	name?: string;
	language?: string | null;
	website?: string | null;
	game_mode?: string | null;
	description?: string | null;
	tags?: string[];
	visible?: boolean;
	visible_in_skin?: boolean;
	group_id?: string | null;
}

export function useUpdateManagedServerMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (opts: {
			params: { ownerId: string; serverId: string };
			body: UpdateManagedServerBody;
		}) => {
			const { ownerId, serverId } = opts.params;
			const res = await fetch(
				`/api/users/management/${ownerId}/tokens/${serverId}`,
				{
					method: "PATCH",
					body: JSON.stringify(opts.body),
					headers: { "Content-Type": "application/json" },
				},
			);

			if (!res.ok) {
				throw new FetchError(res);
			}

			const { data: server } = (await res.json()) as { data: ManagedServer };
			return server;
		},
		onSuccess: async (updatedServer) => {
			queryClient.setQueryData(
				managedServerQuery(updatedServer.user_id, updatedServer.public_id)
					.queryKey,
				updatedServer,
			);
			queryClient.invalidateQueries(myServersQuery);

			const me = await queryClient.ensureQueryData(currentUserQuery);
			const adminBenefit = getUserBenefit(me, "ADMIN");
			if (adminBenefit) {
				queryClient.resetQueries(
					supervisedServerQuery(updatedServer.public_id),
				);
				queryClient.resetQueries(userQuery(updatedServer.user_id));
				queryClient.resetQueries(supervisedServersQuery());
			}
		},
	});
}

export const supervisedServerQuery = (serverId: string) =>
	queryOptions({
		queryKey: ["api", "admin", "tokens", "details", serverId],
		queryFn: async ({ signal }) => {
			const res = await fetch(`/api/admin/tokens/${serverId}`, {
				signal,
			});
			if (!res.ok) {
				throw new FetchError(res);
			}
			const { data: server } = (await res.json()) as { data: Server };
			return server;
		},
	});

export type GetSupervicedServersSearchParams = {
	verified?: boolean;
	visible?: boolean;
	banned?: boolean;
	game_mode?: string;
	website?: string;
	vanity_url?: string;
	ip?: string;
	public_id?: string;
	name?: string;
	id?: string;
	page?: number;
	limit?: number;
};

export const supervisedServersQuery = (
	params?: GetSupervicedServersSearchParams,
) =>
	queryOptions({
		queryKey: params
			? ["api", "admin", "tokens", "list", params]
			: ["api", "admin", "tokens", "list"],
		queryFn: async ({ signal }) => {
			const search = params ? createURLSearchParams(params).toString() : "";
			const res = await fetch("/api/admin/tokens?" + search, {
				signal,
			});
			if (!res.ok) {
				throw new FetchError(res);
			}
			return (await res.json()) as ServersPagingObject;
		},
		staleTime: Infinity,
	});

export interface UpdateServerBody {
	name?: string;
	vanity_url?: string;
	language?: string | null;
	website?: string | null;
	game_mode?: string | null;
	description?: string | null;
	tags?: string[];
	visible?: boolean;
	visible_in_skin?: boolean;
	group_id?: string | null;
	verified?: boolean;
}

export function useUpdateSupervisedServerMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (opts: {
			params: {
				serverId: string;
			};
			body: UpdateServerBody;
		}) => {
			const res = await fetch(`/api/admin/tokens/${opts.params.serverId}`, {
				method: "PATCH",
				body: JSON.stringify(opts.body),
				headers: { "Content-Type": "application/json" },
			});

			if (!res.ok) {
				throw new FetchError(res);
			}

			const { data: server } = (await res.json()) as { data: Server };
			return server;
		},
		onSuccess: async (updatedServer) => {
			queryClient.setQueryData(
				supervisedServerQuery(updatedServer.public_id).queryKey,
				updatedServer,
			);
			queryClient.invalidateQueries(userQuery(updatedServer.user_id));
			queryClient.resetQueries(supervisedServersQuery());

			const me = await queryClient.ensureQueryData(currentUserQuery);
			if (me.id === updatedServer.user_id) {
				queryClient.setQueryData(
					ownedServerQuery(updatedServer.public_id).queryKey,
					updatedServer,
				);
				queryClient.invalidateQueries(myServersQuery);
			}
		},
	});
}

export function useResetSupervisedServerIPMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (options: { serverId: string }) => {
			const res = await fetch(
				`/api/admin/tokens/${options.serverId}/reset-ip`,
				{
					method: "PATCH",
				},
			);

			if (!res.ok) {
				throw new FetchError(res);
			}

			const { data: server } = (await res.json()) as { data: Server };
			return server;
		},
		onSuccess: async (updatedServer) => {
			queryClient.setQueryData(
				supervisedServerQuery(updatedServer.public_id).queryKey,
				updatedServer,
			);
			queryClient.invalidateQueries(userQuery(updatedServer.user_id));
			queryClient.resetQueries(supervisedServersQuery());

			const me = await queryClient.ensureQueryData(currentUserQuery);
			if (me.id === updatedServer.user_id) {
				queryClient.setQueryData(
					ownedServerQuery(updatedServer.public_id).queryKey,
					updatedServer,
				);
				queryClient.invalidateQueries(myServersQuery);
			}
		},
	});
}

export function useToggleServerBanStatusMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (params: { serverId: string }) => {
			const res = await fetch(`/api/admin/tokens/${params.serverId}/ban`, {
				method: "PATCH",
			});

			if (!res.ok) {
				throw new FetchError(res);
			}

			const { data: server } = (await res.json()) as { data: Server };
			return server;
		},
		onSuccess: async (updatedServer) => {
			queryClient.setQueryData(
				supervisedServerQuery(updatedServer.public_id).queryKey,
				updatedServer,
			);
			queryClient.invalidateQueries(userQuery(updatedServer.user_id));
			queryClient.resetQueries(supervisedServersQuery());

			const me = await queryClient.ensureQueryData(currentUserQuery);
			if (me.id === updatedServer.user_id) {
				queryClient.setQueryData(
					ownedServerQuery(updatedServer.public_id).queryKey,
					updatedServer,
				);
				queryClient.invalidateQueries(myServersQuery);
			}
		},
	});
}

export function useDeleteSupervisedServerMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (params: { ownerId: string; serverId: string }) => {
			const res = await fetch(`/api/admin/tokens/${params.serverId}`, {
				method: "DELETE",
			});

			if (!res.ok) {
				throw new FetchError(res);
			}
		},
		onSuccess: async (_, { serverId, ownerId }) => {
			queryClient.removeQueries(supervisedServerQuery(serverId));
			queryClient.setQueryData(userQuery(ownerId).queryKey, (user) => {
				if (!user) return user;
				return {
					...user,
					masterListTokens: user.masterListTokens.filter(
						(server) => server.public_id !== serverId,
					),
				};
			});
			queryClient.resetQueries(supervisedServersQuery());

			const me = await queryClient.ensureQueryData(currentUserQuery);
			if (me.id === ownerId) {
				queryClient.removeQueries(ownedServerQuery(serverId));
				queryClient.setQueryData(myServersQuery.queryKey, (servers) => {
					if (!servers) return servers;
					return {
						...servers,
						owned: servers.owned.filter((s) => s.public_id !== serverId),
					};
				});
			}
		},
	});
}

export interface TransferServerBody {
	token_id: string;
	to_user_id: string;
}

export function useTransferServerMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (body: TransferServerBody) => {
			const res = await fetch("/api/users/tokens/transfers/create", {
				method: "POST",
				body: JSON.stringify(body),
				headers: { "Content-Type": "application/json" },
			});

			if (!res.ok) {
				throw new FetchError(res);
			}

			const { data: transfer } = (await res.json()) as { data: ServerTransfer };
			return transfer;
		},
		onSuccess: (transfer) => {
			queryClient.setQueryData(myServersQuery.queryKey, (groups) => {
				if (!groups) return groups;
				return {
					...groups,
					outgoing_transfers: [...groups.outgoing_transfers, transfer],
				};
			});
		},
	});
}

export function useAcceptServerTransferMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (params: { transferId: string }) => {
			const res = await fetch(
				`/api/users/tokens/transfers/${params.transferId}/accept`,
				{
					method: "POST",
				},
			);

			if (!res.ok) {
				throw new FetchError(res);
			}
		},
		onSuccess: async (_, { transferId }) => {
			queryClient.invalidateQueries(myServersQuery);

			const me = await queryClient.ensureQueryData(currentUserQuery);
			const adminBenefit = getUserBenefit(me, "ADMIN");

			if (adminBenefit) {
				queryClient.invalidateQueries(userQuery(me.id));
				queryClient.resetQueries(supervisedServersQuery());

				const servers = await queryClient.ensureQueryData(myServersQuery);
				const transfer = servers.incoming_transfers.find(
					(transfer) => transfer.id === transferId,
				);
				if (transfer) {
					queryClient.invalidateQueries(userQuery(transfer.from_user.id));
				}
			}
		},
	});
}

export function useCancelServerTransferMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (params: { transferId: string }) => {
			const res = await fetch(
				`/api/users/tokens/transfers/${params.transferId}/cancel`,
				{
					method: "POST",
				},
			);

			if (!res.ok) {
				throw new FetchError(res);
			}
		},
		onSuccess: async (_, { transferId }) => {
			queryClient.invalidateQueries(myServersQuery);

			const me = await queryClient.ensureQueryData(currentUserQuery);
			const adminBenefit = getUserBenefit(me, "ADMIN");
			if (adminBenefit) {
				queryClient.resetQueries(userQuery(me.id));

				const servers = await queryClient.ensureQueryData(myServersQuery);
				const transfer = servers.incoming_transfers.find(
					(transfer) => transfer.id === transferId,
				);
				if (transfer) {
					queryClient.invalidateQueries(userQuery(transfer.from_user.id));
				}
			}
		},
	});
}
