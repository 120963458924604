import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M10.7 11.3 14 8m0 0-3.3-3.3M14 8H6m0-6h-.8c-1.1 0-1.7 0-2.1.2a2 2 0 0 0-.9.9C2 3.5 2 4 2 5.2v5.6c0 1.1 0 1.7.2 2.1.2.4.5.7.9.9.4.2 1 .2 2.1.2H6",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      stroke: "currentColor",
      "vector-effect": "non-scaling-stroke"
    }, null, -1)
  ])))
}
export default { render: render }