import type { BenefitKey, UserBenefit } from "./benefits.types";
import type { User } from "./users.types";
import { computed, type ComputedRef, type Ref } from "vue";

export function getUserBenefit<T extends BenefitKey>(
	user: User,
	benefitKey: T,
): UserBenefit | undefined {
	return user.benefits.find((benefit) => benefit.key === benefitKey);
}

export function useUserBenefit<T extends BenefitKey>(
	user: Ref<User | null | undefined>,
	benefitKey: T,
): ComputedRef<UserBenefit | undefined> {
	return computed(() => {
		if (!user.value) return;
		return getUserBenefit(user.value, benefitKey);
	});
}

/**
 * Since the Server Manager API returns only the avatar hash, we need to create
 * a link for the avatar ourselves.
 *
 * @see https://discord.com/developers/docs/reference#image-formatting
 */
export function getDiscordAvatarUrl(
	discord: { discord_id: string; avatar: string | null },
	size = 128,
): string | undefined {
	if (!discord.avatar) return;
	return `https://cdn.discordapp.com/avatars/${discord.discord_id}/${discord.avatar}.png?size=${size}`;
}

export function useUserAvatarUrl(
	user: Ref<User | null | undefined>,
): ComputedRef<string | undefined> {
	return computed(() => {
		if (!user.value || !user.value.discord.avatar) return;
		return getDiscordAvatarUrl(user.value.discord);
	});
}
