import { QueryClient } from "@tanstack/vue-query";
import { FetchError } from "@/utils";

const retryOnInternalErrors = (failureCount: number, error: Error) => {
	if (
		error instanceof FetchError &&
		error.response.status >= 500 &&
		error.response.status !== 503 && // don't retry when feature is disabled
		failureCount <= 1
	) {
		return true;
	}
	return false;
};

export const queryClient = new QueryClient({
	defaultOptions: {
		mutations: {
			retry: retryOnInternalErrors,
		},
		queries: {
			retry: retryOnInternalErrors,
			refetchOnMount: false,
			refetchOnWindowFocus: import.meta.env.PROD,
		},
	},
});
