<script setup lang="ts">
import { computed } from "vue";
import Countdown from "@chenfengyuan/vue-countdown";
import { useQuery } from "@tanstack/vue-query";
import { useRouter } from "vue-router";
import {
	currentUserQuery,
	useAbortSelfDeletionMutation,
} from "@/api/users.endpoints";
import { useLogoutMutation } from "@/api/auth";
import LogoutIcon from "@/icons/logout.svg?component";
import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/spinner";
import { getDiscordAvatarUrl } from "@/api/users.utils";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import AlertCircleOutlineIcon from "@/icons/alert-circle-outline.svg";
import { push } from "notivue";
import { getAPIErrorMessage } from "@/api/utils";

const { data: me } = useQuery(currentUserQuery);
const router = useRouter();

const { mutate: logout, isPending: isLogoutPedning } = useLogoutMutation();
const { mutate: cancelSelfDelete, isPending: isCancelDeletePending } =
	useAbortSelfDeletionMutation();

const delta = computed(() => {
	if (!me.value || !me.value.delete_at) return 0;
	return new Date(me.value.delete_at).getTime() - Date.now();
});

const handleCancelDelete = () => {
	cancelSelfDelete(undefined, {
		onSuccess: () => router.push("/"),
		onError: async (error) => {
			push.error({
				title: "Failed to cancel deletion",
				message: await getAPIErrorMessage(error),
			});
		},
	});
};

const handleLogout = () => {
	logout(undefined, {
		onSuccess: () => location.assign("/login"),
		onError: async (error) => {
			push.error({
				title: "Failed to cancel deletion",
				message: await getAPIErrorMessage(error),
			});
		},
	});
};
</script>

<template>
	<div v-if="me && me.delete_at" class="fixed top-0 z-20 flex w-full p-3">
		<div
			class="mx-auto flex max-w-2xl flex-1 items-center justify-between gap-3 overflow-hidden rounded-md border border-stone-500 bg-stone-700 p-3"
		>
			<div class="flex min-w-0 items-center gap-2">
				<Avatar class="size-8">
					<AvatarImage :src="getDiscordAvatarUrl(me.discord) || ''" />
					<AvatarFallback class="text-sm">
						{{ me.discord.username[0]!.toUpperCase() }}
					</AvatarFallback>
				</Avatar>
				<span class="truncate text-sm font-medium">
					{{ me.discord.username }}
				</span>
			</div>
			<div class="flex gap-2">
				<Button
					:disabled="isCancelDeletePending"
					class="relative flex-1"
					size="xs"
					variant="outline_white"
					@click="handleCancelDelete"
				>
					<Spinner
						v-if="isCancelDeletePending"
						class="absolute mx-auto size-5"
					/>
					<span :class="isCancelDeletePending ? 'invisible' : ''">
						Cancel deletion
					</span>
				</Button>
				<Tooltip>
					<TooltipTrigger>
						<Button
							variant="secondary"
							class="size-8 p-0 text-white/60"
							@click="handleLogout"
						>
							<Spinner v-if="isLogoutPedning" class="absolute mx-auto size-4" />
							<LogoutIcon v-else class="size-4 stroke-[1.5]" />
						</Button>
					</TooltipTrigger>
					<TooltipContent>Logout</TooltipContent>
				</Tooltip>
			</div>
		</div>
	</div>
	<div v-if="me && me.delete_at" class="relative px-6">
		<div class="mx-auto flex min-h-dvh max-w-2xl">
			<div class="flex flex-1 flex-col items-start justify-center gap-6">
				<div class="rounded-full bg-red-400/15 p-2 ring-8 ring-red-400/5">
					<AlertCircleOutlineIcon class="size-8 stroke-[2.5] text-red-400" />
				</div>
				<div class="flex flex-col gap-3">
					<h2
						class="text-balance text-3xl font-semibold tracking-tight md:text-4xl"
					>
						Your account is set for deletion
					</h2>
					<p class="leading-normal text-white/75">
						Please note that you have a limited time to change your mind before
						the countdown ends. Once the timer expires, your data will be
						permanently erased from our servers, and it will not be recoverable.
					</p>
				</div>
				<Countdown
					:time="delta"
					v-slot="{ hours, minutes, seconds }"
					class="mt-2 flex w-full gap-1 text-center"
				>
					<div class="flex flex-1 flex-col rounded-md bg-stone-800">
						<div
							class="flex h-12 w-full items-center justify-center rounded-lg bg-stone-700 font-mono text-3xl font-medium tabular-nums tracking-tight text-white/75 shadow-[0px_1px_0px_0px_rgba(255,255,255,0.06)_inset]"
						>
							{{ hours }}
						</div>
						<span class="py-1.5 text-xs tracking-wide text-white/50">
							Hours
						</span>
					</div>
					<span class="py-2 text-2xl text-white/25">:</span>
					<div class="flex flex-1 flex-col rounded-md bg-stone-800">
						<div
							class="flex h-12 w-full items-center justify-center rounded-lg bg-stone-700 font-mono text-3xl font-medium tabular-nums tracking-tight text-white/75 shadow-[0px_1px_0px_0px_rgba(255,255,255,0.06)_inset]"
						>
							{{ minutes }}
						</div>
						<span class="py-1.5 text-xs tracking-wide text-white/50">
							Minutes
						</span>
					</div>
					<span class="py-2 text-2xl text-white/25">:</span>
					<div class="flex flex-1 flex-col rounded-md bg-stone-800">
						<div
							class="flex h-12 w-full items-center justify-center rounded-lg bg-stone-700 font-mono text-3xl font-medium tabular-nums tracking-tight text-white/75 shadow-[0px_1px_0px_0px_rgba(255,255,255,0.06)_inset]"
						>
							{{ seconds }}
						</div>
						<span class="py-1.5 text-xs tracking-wide text-white/50">
							Seconds
						</span>
					</div>
				</Countdown>
			</div>
		</div>
	</div>
</template>
