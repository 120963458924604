import { FetchError } from "@/utils";
import { z } from "zod";

const errorResponseSchema = z.object({
	statusCode: z.number(),
	errors: z.array(z.string()),
	isBusiness: z.boolean(),
});

export type ErrorResponse = z.infer<typeof errorResponseSchema>;

export function isAPIErrorReponse(value: unknown): value is ErrorResponse {
	return errorResponseSchema.safeParse(value).success;
}

export const getAPIErrorMessage = (error: Error): Promise<string> => {
	if (!(error instanceof FetchError)) {
		return Promise.resolve(error.message);
	}
	return error.response
		.clone()
		.json()
		.then((data) => {
			if (isAPIErrorReponse(data)) {
				return data.errors.join(", ");
			}
			return error.response.statusText;
		})
		.catch(() => error.response.statusText);
};

const createServerErrorResponseSchema = errorResponseSchema.merge(
	z.object({
		businessStatus: z
			.enum(["NAME_ALREADY_EXISTS", "VANITY_URL_ALREADY_EXISTS"])
			.optional(),
	}),
);

export type CreateServerErrorResponse = z.infer<
	typeof createServerErrorResponseSchema
>;

export function isCreateServerErrorResponse(
	value: unknown,
): value is CreateServerErrorResponse {
	return createServerErrorResponseSchema.safeParse(value).success;
}
