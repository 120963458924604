<script setup lang="ts">
import { AvatarRoot, type AvatarRootProps } from "radix-vue";
import { twMerge, type ClassNameValue } from "tailwind-merge";
import { computed } from "vue";

const props = defineProps<AvatarRootProps & { class?: ClassNameValue }>();

const classes = computed(() => {
	return twMerge(
		"flex size-10 shrink-0 select-none items-center justify-center overflow-hidden rounded-full bg-white/10 text-white/50",
		props.class,
	);
});
</script>

<template>
	<AvatarRoot v-bind="{ ...props, class: classes, size: undefined }">
		<slot />
	</AvatarRoot>
</template>
