import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 72 72"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("path", {
      opacity: ".15",
      "stroke-dasharray": "40",
      "stroke-dashoffset": "80",
      style: {"animation":"line-animation 1s linear infinite"},
      d: "m3.5 8.1 6 51.2a6.5 6.5 0 0 0 5.7 5.4l49.3 4.1c1.2.1 2.2-.3 3-1 .6-.7 1-1.7.8-3l-5.1-48c-.3-2.6-2.6-5-5.2-5.4L8 4a4 4 0 0 0-3.4.8c-.8.8-1.2 1.8-1 3.2Zm-.7 0 6 51.3c.3 3 3.2 5.8 6.3 6l49.4 4.1c2.8.3 4.8-1.9 4.5-4.8l-5.1-48c-.3-2.9-3-5.6-5.8-6l-50-7.3C4.7 2.9 2.4 5 2.7 8.2Z",
      stroke: "#F1F2F2"
    }, null, -1)),
    (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" @keyframes line-animation { to { stroke-dashoffset: 0; } } ")
      ])),
      _: 1
    })),
    _cache[2] || (_cache[2] = _createElementVNode("path", {
      d: "M20.1 22.7 36 50.3l15.9-27.6H30.7L36 32l2.6-4.5H44L36 41 25.4 22.7h-5.3Z",
      fill: "#F1F2F2",
      opacity: ".5"
    }, null, -1))
  ]))
}
export default { render: render }