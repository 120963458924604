import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M13.13 17.5a.6.6 0 0 1-.63.63h-5a.62.62 0 1 1 0-1.25h5a.6.6 0 0 1 .63.62m5-12.5v8.75a1.87 1.87 0 0 1-1.88 1.88H3.75a1.87 1.87 0 0 1-1.87-1.88V5a1.9 1.9 0 0 1 1.87-1.87h12.5A1.87 1.87 0 0 1 18.13 5M12.8 9.38a.6.6 0 0 0-.26-.52L9.42 6.68a.63.63 0 0 0-.98.5v4.38a.62.62 0 0 0 .98.51l3.13-2.18a.6.6 0 0 0 .26-.52",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }