<script setup lang="ts">
import { SERVER_FILTER_CONTEXT } from "@/api/server.utils";
import { userQuery } from "@/api/users.endpoints";
import ChangeUserLimitsPopover from "@/components/change-user-limits-popover/ChangeUserLimitsPopover.vue";
import ServerCard from "@/components/server-card/ServerCard.vue";
import { SearchInput } from "@/components/ui/search-input";
import { useQuery } from "@tanstack/vue-query";
import { useThrottleFn } from "@vueuse/core";
import { useRouteQuery } from "@vueuse/router";
import { computed, provide } from "vue";
import { useRoute, type RouteLocationNormalizedLoaded } from "vue-router";

definePage({ alias: "/admin/users/:user_id/" });

const route =
	useRoute() as RouteLocationNormalizedLoaded<"//admin/users/[user_id]/servers">;

const search = useRouteQuery<string>("search", "");
const setSearchWithThrottle = useThrottleFn(
	(newValue: string) => (search.value = newValue),
	250,
	true,
);

provide(SERVER_FILTER_CONTEXT, { search });

const { data: user } = useQuery(userQuery(route.params.user_id));

const filteredServers = computed(() => {
	if (!user.value) return [];
	if (!search.value) return user.value.masterListTokens;
	const lowercaseSearch = search.value.toLowerCase();
	return user.value.masterListTokens.filter((server) => {
		return server.name.toLowerCase().includes(lowercaseSearch);
	});
});
</script>

<template>
	<div class="mb-6 flex justify-between gap-6">
		<h2 class="text-3xl font-medium leading-10 tracking-tight">Servers</h2>
		<div class="flex gap-2">
			<SearchInput
				:model-value="search"
				@update:model-value="setSearchWithThrottle"
				placeholder="Search..."
				class="w-full text-sm"
			/>
			<ChangeUserLimitsPopover :user-id="route.params.user_id" />
		</div>
	</div>
	<div
		v-if="user && user.masterListTokens.length === 0"
		class="relative flex w-full items-center justify-center overflow-hidden rounded-xl border border-white/10 bg-gradient-to-b from-stone-800 px-5 py-10 text-center lg:px-10"
	>
		<!-- <ServersOutlineIcon class="mr-2.5 size-5 stroke-[1.5] text-white/50" /> -->
		<span class="text-balance text-sm text-white/60">
			User has no servers
		</span>
	</div>
	<ul
		v-if="filteredServers.length"
		class="grid gap-4 sm:grid-cols-2 lg:grid-cols-3"
	>
		<ServerCard
			v-for="item of filteredServers"
			:server="item"
			:is-transferring="false"
			access-type="admin"
		/>
	</ul>
</template>
