<script setup lang="tsx">
import { protectionIssueQuery } from "@/api/protection-issues";
import { userQuery } from "@/api/users.endpoints";
import { queryClient } from "@/queryClient";
import { useQuery } from "@tanstack/vue-query";
import {
	RouterLink,
	useRoute,
	type RouteLocationNormalized,
	type RouteLocationNormalizedLoaded,
} from "vue-router";
import SlashIcon from "@/icons/slash.svg?component";
import { getDiscordAvatarUrl } from "@/api/users.utils";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { supervisedServerQuery } from "@/api/servers.endpoints";
import { getServerDefaultIcon } from "@/data/servers";
import dayjs from "dayjs";
import FileLockIcon from "@/icons/file-lock.svg?component";
import DownloadIcon from "@/icons/download.svg?component";
import UserScannerIcon from "@/icons/user-scanner.svg?component";
import { TableCellContent, type TableColumn } from "@/components/ui/table";

definePage({
	// @ts-expect-error
	beforeEnter: async (
		to: RouteLocationNormalized<"//admin/protection-issues/[issue_id]/users/[user_id]">,
	) => {
		await queryClient.ensureQueryData(
			protectionIssueQuery(to.params.user_id, to.params.issue_id),
		);
	},
});

const route =
	useRoute() as RouteLocationNormalizedLoaded<"//admin/protection-issues/[issue_id]/users/[user_id]">;

const { data: issue } = useQuery(
	protectionIssueQuery(route.params.user_id, route.params.issue_id),
);
if (!issue.value) {
	throw new Error("Protection issue not found");
}

const { data: server } = useQuery(
	supervisedServerQuery(issue.value.token.public_id),
);
const { data: user } = useQuery(userQuery(route.params.user_id));

const columns: TableColumn<null>[] = [
	{
		key: "server",
		header: "Server",
		cell: () => {
			if (!server.value) return null;
			return (
				<RouterLink
					to={`/admin/servers/${issue.value.token.public_id}`}
					class="flex items-center gap-2 underline-offset-2 hover:underline"
				>
					<Avatar class="size-6">
						<AvatarImage
							src={
								server.value.masterlist_icon_url ||
								getServerDefaultIcon(server.value.public_id)
							}
						/>
						<AvatarFallback />
					</Avatar>
					<span class="text-sm font-medium">{issue.value.token.name}</span>
				</RouterLink>
			);
		},
	},
	{
		key: "user_id",
		header: "User ID",
		cell: () => {
			return (
				<code class="rounded bg-stone-600 px-2 py-1">
					{issue.value.user_id}
				</code>
			);
		},
	},
	{
		key: "hwid",
		header: "Hardware ID",
		cell: () => {
			return (
				<code class="rounded bg-stone-600 px-2 py-1">{issue.value.hwid}</code>
			);
		},
	},
	{
		key: "type",
		header: "Type",
		cell: () => {
			if (issue.value.type === null) return null;
			return (
				<span class="inline-flex h-5 items-center rounded-full bg-red-400/15 px-2 text-xs font-medium text-red-300">
					{issue.value.type}
				</span>
			);
		},
	},
	{
		key: "created_at",
		header: "Created at",
		cell: () => {
			return dayjs(issue.value.created_at).format("MMMM D, YYYY HH:mm");
		},
	},
];
</script>

<template>
	<div
		class="pointer-events-none absolute -z-[1] h-80 w-full bg-gradient-to-b from-stone-700/90"
	></div>
	<div
		v-if="issue"
		class="relative border-b border-white/10 px-6 py-3 sm:px-10"
	>
		<div class="mx-auto flex min-w-0 max-w-3xl flex-col items-start gap-6">
			<div class="flex min-w-0 items-center">
				<RouterLink
					:to="`/admin/users/${route.params.user_id}/`"
					class="-ml-1 flex items-center gap-x-2 rounded-full py-1 pl-1 pr-1 transition-colors hover:bg-white/10 xs:pr-3"
				>
					<Avatar v-if="user" class="size-6">
						<AvatarImage :src="getDiscordAvatarUrl(user.discord) || ''" />
						<AvatarFallback class="text-xs">
							{{ user.discord.username[0]!.toUpperCase() }}
						</AvatarFallback>
					</Avatar>
					<p
						v-if="user"
						class="hidden min-w-0 flex-1 select-none truncate text-sm text-slate-50 xs:inline-block"
					>
						{{ user.discord.username }}
					</p>
				</RouterLink>
				<SlashIcon class="size-5 flex-shrink-0 stroke-white/25 stroke-[1.5]" />
				<div class="flex h-8 min-w-0 shrink items-center px-2">
					<UserScannerIcon class="mr-2 size-4 shrink-0 text-white/50" />
					<span class="block flex-1 select-none truncate text-sm text-stone-50">
						Protection issues
					</span>
				</div>
			</div>
		</div>
	</div>
	<div v-if="issue" class="h-full px-6 py-8 sm:p-10">
		<div class="mx-auto flex max-w-3xl flex-col gap-6">
			<div class="flex flex-col gap-2">
				<span class="text-sm text-white/60">Issue {{ issue.id }}</span>
				<h1 class="text-2xl font-medium sm:text-3xl">
					{{ issue.description }}
				</h1>
			</div>
			<div
				class="overflow-x-auto overflow-y-hidden rounded-md border border-stone-600 bg-stone-800"
			>
				<table class="w-full whitespace-nowrap">
					<tbody class="divide-y divide-stone-600">
						<tr class="divide-x divide-stone-600" v-for="column of columns">
							<td scope="row" class="px-4 py-3 text-sm text-white/60">
								<TableCellContent :render="column.header" />
							</td>
							<td class="px-4 py-3 text-sm">
								<TableCellContent :render="column.cell" />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="flex flex-col gap-2">
				<div class="flex items-center gap-2">
					<h2 class="text-xl font-medium">Attachments</h2>
					<span
						class="rounded-full bg-white/10 px-2 text-xs font-medium leading-5 text-white/60"
					>
						{{ issue.attachments.length }}
					</span>
				</div>
				<span class="text-sm text-white/60">
					Encrypted files that offer extra details to help identify the issue.
				</span>
			</div>
			<ul
				class="flex flex-col divide-y divide-stone-600 rounded-md border border-stone-600"
			>
				<li
					v-for="attachment of issue.attachments"
					class="flex items-center p-3"
				>
					<div class="flex flex-1">
						<div class="mr-3 rounded bg-white/10 p-2">
							<FileLockIcon class="size-6 text-stone-300" />
						</div>
						<div class="flex flex-col gap-0.5">
							<span class="text-sm">
								{{ attachment.fileName }}
							</span>
							<span class="text-xs text-white/60">{{
								attachment.mimeType
							}}</span>
						</div>
					</div>
					<a
						:href="attachment.url"
						class="rounded p-2 hover:bg-white/10 active:bg-white/15"
						download
					>
						<DownloadIcon class="size-4 stroke-[1.5] text-white/60" />
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>
