import type { BenefitKey } from "@/api/benefits.types";
import type { SVGAttributes, FunctionalComponent } from "vue";
import CrownIcon from "@/icons/crown.svg?component";
import LightingIcon from "@/icons/lighting.svg?component";
import CloudShieldIcon from "@/icons/cloud-shield.svg?component";
import ImagesIcon from "@/icons/images.svg?component";
import GridLayoutIcon from "@/icons/grid-layout.svg?component";
import BgImageIcon from "@/icons/bg-image.svg?component";
import ShareIcon from "@/icons/share.svg?component";
import PlayScreenIcon from "@/icons/play-screen.svg?component";
import CodeWindowIcon from "@/icons/code-window.svg?component";

export const benefitNames: Readonly<Record<BenefitKey, string>> = Object.freeze(
	{
		ADMIN: "Admin",
		PROMOTED: "Promotion",
		CLIENT_CLOUD_AUTH: "Cloud Auth",
		CUSTOM_LAUNCHER: "Custom Launcher",
		CUSTOM_UI_URL: "Custom Launcher UI",
		CUSTOM_BANNER: "Custom Banner",
		CUSTOM_VANITY_URL: "Custom Vanity URL",
		CUSTOM_JOIN_BACKGROUND: "Custom Join Background",
		MASTERLIST_GROUPS: "Server Groups",
		MASTERLIST_TOKEN_CARD_ICONS: "Custom Server Appearance",
	},
);

export const benefitDescriptions: Readonly<Record<BenefitKey, string>> =
	Object.freeze({
		ADMIN: "With great power comes great responsibility.",
		PROMOTED:
			"Moves all your servers to the top of the server list (can be disabled).",
		CLIENT_CLOUD_AUTH: "Unique identification solution developed by alt:V.",
		CUSTOM_LAUNCHER:
			"A personalized version of the alt:V client where only your servers are visible.",
		CUSTOM_UI_URL: "The ability to use your own hosted UI for custom launcher.",
		CUSTOM_BANNER:
			"Set a banner image for the server connect modal in alt:V client.",
		CUSTOM_VANITY_URL:
			"Set a custom ID for your servers, used in access links.",
		CUSTOM_JOIN_BACKGROUND:
			"Set your own background image for the server connection screen.",
		MASTERLIST_GROUPS: "Create and manage groups of servers.",
		MASTERLIST_TOKEN_CARD_ICONS:
			"Set custom icons and banners for your servers.",
	});

export const benefitsWithSettingsPage = Object.freeze([
	"CUSTOM_BANNER",
	"CUSTOM_LAUNCHER",
	"CUSTOM_JOIN_BACKGROUND",
] satisfies BenefitKey[]);
export type BenefitWithSettingsPage = (typeof benefitsWithSettingsPage)[number];

export const benefitIcons: Readonly<
	Record<BenefitKey, FunctionalComponent<SVGAttributes>>
> = Object.freeze({
	ADMIN: CrownIcon,
	PROMOTED: LightingIcon,
	CLIENT_CLOUD_AUTH: CloudShieldIcon,
	CUSTOM_LAUNCHER: PlayScreenIcon,
	CUSTOM_UI_URL: CodeWindowIcon,
	CUSTOM_BANNER: BgImageIcon,
	CUSTOM_VANITY_URL: ShareIcon,
	CUSTOM_JOIN_BACKGROUND: BgImageIcon,
	MASTERLIST_GROUPS: GridLayoutIcon,
	MASTERLIST_TOKEN_CARD_ICONS: ImagesIcon,
});

/**
 * Benefit icon colors in HSL format.
 */
export const benefitIconColors: Readonly<
	Record<BenefitKey, readonly [number, number, number]>
> = Object.freeze({
	ADMIN: [47.95, 95.82, 53.14],
	PROMOTED: [36.06, 95.31, 58.24],
	CLIENT_CLOUD_AUTH: [143.92, 100, 70.98],
	CUSTOM_LAUNCHER: [143.92, 100, 70.98],
	CUSTOM_UI_URL: [143.92, 100, 70.98],
	CUSTOM_BANNER: [211.7, 96.36, 78.43],
	CUSTOM_VANITY_URL: [211.7, 96.36, 78.43],
	CUSTOM_JOIN_BACKGROUND: [211.7, 96.36, 78.43],
	MASTERLIST_GROUPS: [0, 0, 64],
	MASTERLIST_TOKEN_CARD_ICONS: [143.92, 100, 70.98],
});

export const benefitFileKeys = Object.freeze({
	CUSTOM_BANNER: {
		banner: "banner",
	},
	CUSTOM_LAUNCHER: {
		launcherBackground: "launcherBackground",
		logo: "logo",
		uiBackground: "uiBackground",
		installerBackground: "installerBackground",
		customUiImageAppIcon: "customUiImageAppIcon",
	},
	CUSTOM_JOIN_BACKGROUND: { customJoinBanner: "customJoinBanner" },
	MASTERLIST_TOKEN_CARD_ICONS: {
		icon: (serverId: string) => `${serverId}/icon` as const,
		banner: (serverId: string) => `${serverId}/banner` as const,
	},
	MASTERLIST_GROUPS: {
		icon: (serverGroupId: string) => `${serverGroupId}/icon` as const,
	},
} as const);

/**
 * Tier colors in HSL format.
 */
export const tierColors: Readonly<
	Record<string, readonly [number, number, number]>
> = Object.freeze({
	"Bronze Altruist": [19.44, 42.86, 50.59],
	"Silver Altruist": [0, 0, 58.82],
	"Gold Altruist": [34.92, 50.41, 52.55],
	"Platinum Altruist": [315.45, 28.7, 45.1],
	"Ruby Altruist": [0, 50.26, 62.94],
	"Diamond Altruist": [47.92, 73.47, 61.57],
});

export const tierFallbackColor = Object.freeze([0, 0, 58.82] as const);
