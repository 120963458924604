import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "m18.57 9.2-6.25 6.24a.63.63 0 0 1-1.07-.44v-3.1c-4.46.25-7.52 3.14-8.38 4.06a.94.94 0 0 1-1.61-.75 10.7 10.7 0 0 1 3.88-6.83 11.3 11.3 0 0 1 6.11-2.73V2.5a.63.63 0 0 1 1.07-.44l6.25 6.25a.63.63 0 0 1 0 .88",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }