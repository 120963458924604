import { defineAsyncComponent, type Component } from "vue";
import { benefitsWithSettingsPage } from "@/data/benefits";

export default {
	CUSTOM_LAUNCHER: defineAsyncComponent(
		() => import("./CustomLauncherSettingsForm.vue"),
	),
	CUSTOM_BANNER: defineAsyncComponent(
		() => import("./CustomBannerSettingsForm.vue"),
	),
	CUSTOM_JOIN_BACKGROUND: defineAsyncComponent(
		() => import("./CustomJoinBackgroundSettingsForm.vue"),
	),
} as Record<(typeof benefitsWithSettingsPage)[number], Component>;
