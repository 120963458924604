<script setup lang="ts">
import { currentUserQuery } from "@/api/users.endpoints";
import { useQuery } from "@tanstack/vue-query";
import { benefitsWithSettingsPage } from "@/data/benefits";
import { useRoute, type RouteLocationNormalized } from "vue-router";
import { BENEFIT_KEYS } from "@/api/benefits.types";
import { queryClient } from "@/queryClient";
import type { RouteLocationNormalizedLoaded } from "vue-router";
import benefitSettingsFormsComponents from "@/components/benefits-settings";
import BenefitSettingsPageHeader from "@/components/benefits-settings/BenefitSettingsPageHeader.vue";

definePage({
	// @ts-expect-error
	beforeEnter: async (
		to: RouteLocationNormalized<"//benefits/[benefit_key]">,
	) => {
		const benefitKey = to.params.benefit_key.toUpperCase();
		if (!(benefitKey in BENEFIT_KEYS)) {
			throw new Error("Invalid benefit key");
		}
		if (
			!benefitsWithSettingsPage.includes(
				benefitKey as (typeof benefitsWithSettingsPage)[number],
			)
		) {
			throw new Error("Benefit does not have a settings page");
		}
		const me = await queryClient.ensureQueryData(currentUserQuery);
		if (!me.benefits.find((benefit) => benefit.key === benefitKey)) {
			throw new Error("User does not have this benefit");
		}

		await (
			benefitSettingsFormsComponents[
				benefitKey as (typeof benefitsWithSettingsPage)[number]
			] as any
		).__asyncLoader();
	},
});

const route =
	useRoute() as RouteLocationNormalizedLoaded<"//benefits/[benefit_key]">;

const benefitKey =
	route.params.benefit_key.toUpperCase() as (typeof benefitsWithSettingsPage)[number];

const { data: me } = useQuery(currentUserQuery);
</script>

<template>
	<BenefitSettingsPageHeader
		v-if="me"
		access-type="owner"
		:user-id="me.id"
		:benefit-key="benefitKey"
	/>
	<div v-if="me" class="px-6 pb-16 pt-8 sm:px-10">
		<div class="mx-auto flex max-w-4xl flex-col">
			<component
				:is="benefitSettingsFormsComponents[benefitKey]"
				:owner-id="me.id"
				access-type="owner"
			/>
		</div>
	</div>
</template>
