import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M17.36 2H2.64q-.68.01-1.16.47-.46.47-.48 1.13v12.8c0 .42.17.83.48 1.13s.72.47 1.16.47h14.72q.68-.01 1.16-.47.46-.47.48-1.13V3.6c0-.42-.17-.83-.48-1.13S17.8 2 17.36 2m0 3.5a.5.5 0 0 1-.5.5H3.14a.5.5 0 0 1-.5-.5V4.1c0-.28.22-.5.5-.5h13.72c.28 0 .5.22.5.5zM11.5 8.95a.75.75 0 0 1 1.05.05l2.5 2.75c.26.28.26.72 0 1l-2.5 2.75a.75.75 0 0 1-1.1-1l2.04-2.25L11.45 10a.75.75 0 0 1 .05-1.05M7.45 9a.75.75 0 0 1 1.1 1l-2.04 2.25 2.04 2.25a.75.75 0 0 1-1.1 1l-2.5-2.75a.75.75 0 0 1 0-1z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }