import { queryOptions, useMutation, useQueryClient } from "@tanstack/vue-query";
import { FetchError } from "@/utils";

export const discordAuthURLQuery = queryOptions({
	queryKey: ["api", "auth", "discord"],
	queryFn: async ({ signal }): Promise<string> => {
		const res = await fetch("/api/auth/discord", { signal });
		if (!res.ok) {
			throw new FetchError(res);
		}
		return res.text();
	},
});

export function useDiscordAuthMutation() {
	return useMutation({
		mutationFn: async (body: { code: string }) => {
			const res = await fetch("/api/auth/discord", {
				method: "POST",
				body: JSON.stringify(body),
				headers: { "Content-Type": "application/json" },
			});
			if (!res.ok) {
				throw new FetchError(res);
			}
		},
	});
}

export function useLogoutMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async () => {
			const res = await fetch("/api/auth/logout", { method: "POST" });
			if (!res.ok) {
				throw new FetchError(res);
			}
		},
		onSuccess: () => queryClient.clear(),
	});
}
