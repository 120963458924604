<script setup lang="ts">
import { Notivue } from "notivue";
import { onErrorCaptured, ref } from "vue";
import { router } from "@/router";
import AltvLoader from "@/icons/altv-loader.svg?component";
import { TooltipProvider } from "@/components/ui/tooltip";
import { Alert } from "@/components/ui/alert";
import { buttonVariants } from "@/components/ui/button";
import { useQuery } from "@tanstack/vue-query";
import { currentUserQuery } from "@/api/users.endpoints";
import {
	BannedAccountPage,
	DeleteAccountPendingPage,
} from "@/components/layout";

const { data: me } = useQuery(currentUserQuery);

const isReady = ref(false);
const error = ref<unknown>();

router.isReady().finally(() => (isReady.value = true));
router.onError((err) => (error.value = err));

onErrorCaptured((err) => {
	console.error(err);
	error.value = err;
	return false;
});
</script>

<template>
	<div v-if="error instanceof Error" class="px-6 md:px-10">
		<div
			class="container flex h-dvh w-full flex-col justify-center md:max-w-2xl"
		>
			<span class="mb-2 font-medium uppercase tracking-wide text-red-400">
				{{ error.name }}
			</span>
			<h2 class="text-4xl font-semibold leading-[1.1] tracking-tight">
				Whoops, something is wrong :(
			</h2>
			<p class="mt-3 max-w-xl text-base text-white/75 md:text-lg">
				{{ error.message }}
			</p>
			<div class="mt-7">
				<a
					:class="
						buttonVariants({
							size: 'lg',
							variant: 'outline_white',
							class: 'w-full sm:w-auto',
						})
					"
					href="/"
				>
					Go home
				</a>
			</div>
		</div>
	</div>
	<TooltipProvider :disable-closing-trigger="true" :delay-duration="0" v-else>
		<div
			class="flex h-dvh w-full flex-col items-center justify-center"
			v-if="!isReady"
		>
			<AltvLoader class="size-16" />
			<span class="mt-6 block text-sm text-stone-300">Authorizing...</span>
		</div>
		<BannedAccountPage v-else-if="me && me.banned" />
		<DeleteAccountPendingPage v-else-if="me && me.delete_at" />
		<RouterView v-else />
	</TooltipProvider>
	<Notivue v-slot="alert">
		<Alert :alert="alert" />
	</Notivue>
</template>
