import { createURLSearchParams, FetchError } from "@/utils";
import { queryOptions, useMutation, useQueryClient } from "@tanstack/vue-query";

interface ProtectionIssuesServer {
	public_id: string;
	name: string;
	user_id: string;
}

export interface ProtectionIssueAttachment {
	mimeType: string;
	url: string;
	fileName: string;
}

export interface ProtectionIssue {
	id: string;
	user_id: string;
	description: string;
	type: string | null;
	/** Hardware ID */
	hwid: string;
	token: ProtectionIssuesServer;
	created_at: string;
	attachments: ProtectionIssueAttachment[];
}

interface ProtectionIssuesPagingObject {
	data: ProtectionIssue[];
	currentPage: number;
	previousPage: number | null;
	nextPage: number | null;
	pageCount: number;
	totalCount: number;
}

type GetProtectionIssuesOptions = {
	limit?: number;
	page?: number;
	server_public_id?: string;
	/**
	 * Bad guy user id filter
	 */
	offender_user_id?: string;
	type?: string;
	/**
	 * The id of the user who is the administrator of the server to which the protection issues apply. Useful for pass the owner_user_id from manager perspective to filter issues
	 */
	owner_user_id?: string;
	/**
	 * Hardware ID of bad guy
	 */
	hwid?: string;
};

export const protectionIssuesQuery = (options?: GetProtectionIssuesOptions) =>
	queryOptions({
		queryKey: options
			? ["api", "users", "protection-issues", options]
			: ["api", "users", "protection-issues"],
		queryFn: async ({ signal }) => {
			const search = options ? createURLSearchParams(options).toString() : "";
			const res = await fetch("/api/users/protection-issues?" + search, {
				signal,
			});
			if (!res.ok) {
				throw new FetchError(res);
			}

			return res.json() as Promise<ProtectionIssuesPagingObject>;
		},
		staleTime: Infinity,
	});

export const protectionIssueQuery = (userId: string, issueId: string) => {
	return queryOptions({
		queryKey: ["api", "users", userId, "protection-issues", issueId],
		queryFn: async ({ signal }) => {
			const res = await fetch(
				`/api/users/${userId}/protection-issues/${issueId}`,
				{
					signal,
				},
			);
			if (!res.ok) {
				throw new FetchError(res);
			}

			const { data: issue } = (await res.json()) as { data: ProtectionIssue };
			return issue;
		},
	});
};

export const supervisedProtectionIssuesQuery = (
	options?: GetProtectionIssuesOptions,
) =>
	queryOptions({
		queryKey: options
			? ["api", "admin", "protection-issues", options]
			: ["api", "admin", "protection-issues"],
		queryFn: async ({ signal }) => {
			const search = options ? createURLSearchParams(options).toString() : "";
			const res = await fetch("/api/admin/protection-issues?" + search, {
				signal,
			});
			if (!res.ok) {
				throw new FetchError(res);
			}

			return res.json() as Promise<ProtectionIssuesPagingObject>;
		},
		staleTime: Infinity,
	});

interface CreateProtectionIssueBody {
	description: string;
	user_token: string;
	hwid: string;
	type?: string;
	attachments?: File[];
}

export const useCreateProtectionIssueMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (opts: {
			serverId: string;
			body: CreateProtectionIssueBody;
		}) => {
			const formData = new FormData();
			formData.set("description", opts.body.description);
			formData.set("user_token", opts.body.user_token);
			formData.set("hwid", opts.body.hwid);
			if (opts.body.type) {
				formData.set("type", opts.body.type);
			}
			if (opts.body.attachments) {
				for (const attachment of opts.body.attachments) {
					formData.append("attachments[]", attachment);
				}
			}

			const res = await fetch(
				`/api/tokens/${opts.serverId}/protection-issues`,
				{
					method: "POST",
					body: formData,
				},
			);
			if (!res.ok) {
				throw new FetchError(res);
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries(supervisedProtectionIssuesQuery());
		},
	});
};
