import type { NavigationFailure, Router } from "vue-router";

export class FetchError extends Error {
	override name = "FetchError";

	public readonly response: Response;

	constructor(response: Response, options?: ErrorOptions) {
		const message = response.statusText
			? `${response.status} ${response.statusText} (${response.url})`
			: `${response.status} (${response.url})`;

		super(message, options);
		this.response = response;
	}
}

type SearchParam =
	| string
	| number
	| boolean
	| string[]
	| number[]
	| boolean[]
	| undefined;

export function createURLSearchParams(
	init: Record<string, SearchParam>,
): URLSearchParams {
	const searchParams = new URLSearchParams();

	for (const key in init) {
		const value = init[key];

		if (value === undefined) continue;
		if (Array.isArray(value)) {
			for (let i = 0; i < value.length; i++) {
				searchParams.append(key + `[${i}]`, value[i]!.toString());
			}
			continue;
		}

		searchParams.set(key, value.toString());
	}

	return searchParams;
}

export function hashStringToNumber(str: string): number {
	let hash = 0;
	for (const char of str) {
		hash = (hash << 5) - hash + char.charCodeAt(0);
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
}

/**
 * This function replaces the current page state with a previous one.
 * Similar to `router.back()`, but prevents forward navigation in history.
 * Useful when redirecting from a now-invalid page (like a deleted server page)
 * to avoid users navigating back to it.
 */
export function replaceBack(router: Router): Promise<NavigationFailure | void> {
	if (
		"back" in router.options.history.state &&
		typeof router.options.history.state.back === "string"
	) {
		return router.replace(router.options.history.state.back);
	}

	return new Promise<void>((res) => {
		addEventListener(
			"popstate",
			() => {
				history.pushState(history.state, "");
				res();
			},
			{ once: true },
		);

		router.back();
	});
}

interface ImageSize {
	width: number;
	height: number;
}

export async function resizeImage(
	file: File,
	targetSize: ImageSize,
): Promise<File> {
	if (!file.type.startsWith("image/")) {
		throw new Error("Invalid file type. Expected an image.");
	}

	const url = URL.createObjectURL(file);
	const image = new Image();

	const { width: targetWidth, height: targetHeight } = targetSize;
	const { width: currentWidth, height: currentHeight } =
		await new Promise<ImageSize>((res, rej) => {
			image.onload = () => {
				res({ width: image.naturalWidth, height: image.naturalHeight });
			};
			image.onerror = rej;
			image.src = url;
		}).finally(() => URL.revokeObjectURL(url));

	if (
		currentWidth === targetSize.width &&
		currentHeight === targetSize.height
	) {
		return file;
	}

	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");
	if (!ctx) {
		throw new Error("Failed to create 2D context");
	}

	const scale = Math.max(
		targetWidth / currentWidth,
		targetHeight / currentHeight,
	);
	const scaledWidth = currentWidth * scale;
	const scaledHeight = currentHeight * scale;

	canvas.width = targetWidth;
	canvas.height = targetHeight;

	ctx.imageSmoothingEnabled = true;
	ctx.imageSmoothingQuality = "medium";

	const x = (targetWidth - scaledWidth) / 2;
	const y = (targetHeight - scaledHeight) / 2;

	ctx.drawImage(image, x, y, scaledWidth, scaledHeight);

	const blob = await new Promise<Blob>((res, rej) => {
		canvas.toBlob((blob) => {
			if (!blob) {
				rej(new Error("Failed to create blob"));
				return;
			}

			res(blob);
		}, file.type);
	});

	return new File([blob], file.name, { type: file.type });
}

export const hsl = (h: number, s: number, l: number): string =>
	`hsl(${h},${s}%,${l}%)`;

export const hsla = (h: number, s: number, l: number, a: number): string =>
	`hsla(${h},${s}%,${l}%,${a})`;

export const highlight = (text: string, searchValue: string): string => {
	const regex = new RegExp(
		`(${searchValue.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")})`,
		"ig",
	);
	return text.replaceAll(regex, "<mark>$1</mark>");
};
