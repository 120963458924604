<script setup lang="ts">
import { ownedServerQuery } from "@/api/servers.endpoints";
import { queryClient } from "@/queryClient";
import { useQuery } from "@tanstack/vue-query";
import { useRoute, type RouteLocationNormalizedLoaded } from "vue-router";
import type { RouteLocationNormalized } from "vue-router/auto";
import ServerPrivateSection from "@/components/servers/ServerPrivateSection.vue";
import DeleteServerSection from "@/components/servers/DeleteServerSection.vue";
import TransferServerSection from "@/components/servers/TransferServerSection.vue";
import ServerSettingsSection from "@/components/servers/ServerSettingsSection.vue";
import ServerIconUploadField from "@/components/servers/ServerIconUploadField.vue";
import ServerBannerUploadField from "@/components/servers/ServerBannerUploadField.vue";
import { currentUserQuery } from "@/api/users.endpoints";
import ServerPageHeader from "@/components/servers/ServerPageHeader.vue";

definePage({
	// @ts-expect-error
	beforeEnter: async (to: RouteLocationNormalized<"//servers/[server_id]">) => {
		await queryClient.ensureQueryData(ownedServerQuery(to.params.server_id));
	},
});

const { data: me } = useQuery(currentUserQuery);

const route =
	useRoute() as RouteLocationNormalizedLoaded<"//servers/[server_id]">;
</script>

<template>
	<ServerPageHeader
		v-if="me"
		access-type="owner"
		:owner-id="me.id"
		:server-id="route.params.server_id"
	/>
	<div v-if="me" class="px-6 pb-16 pt-8 sm:px-10">
		<div class="mx-auto flex max-w-4xl flex-col gap-6">
			<ServerPrivateSection
				access-type="owner"
				:server-id="route.params.server_id"
			/>
			<ServerSettingsSection
				access-type="owner"
				:server-id="route.params.server_id"
				:owner-id="me.id"
			/>
			<div class="flex flex-col divide-y divide-white/15">
				<ServerIconUploadField
					access-type="owner"
					:server-id="route.params.server_id"
					:owner-id="me.id"
					name="icon"
					class="py-6"
				/>
				<ServerBannerUploadField
					access-type="owner"
					:server-id="route.params.server_id"
					:owner-id="me.id"
					name="banner"
					class="py-6"
				/>
			</div>
			<TransferServerSection :server-id="route.params.server_id" />
			<DeleteServerSection
				access-type="owner"
				:server-id="route.params.server_id"
				:owner-id="me.id"
			/>
		</div>
	</div>
</template>
