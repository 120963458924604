import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M2.68 2h3.64a.7.7 0 0 1 .68.68v3.64c0 .37-.3.68-.68.68H2.68A.7.7 0 0 1 2 6.32V2.68A.7.7 0 0 1 2.68 2m10.64 0H9.68a.7.7 0 0 0-.68.68v3.64c0 .37.3.68.68.68h3.64c.37 0 .68-.3.68-.68V2.68a.7.7 0 0 0-.68-.68M2.68 9h3.64a.7.7 0 0 1 .68.68v3.64c0 .37-.3.68-.68.68H2.68a.7.7 0 0 1-.68-.68V9.68A.7.7 0 0 1 2.68 9m7 0h3.64a.7.7 0 0 1 .68.68v3.64c0 .37-.3.68-.68.68H9.68a.7.7 0 0 1-.68-.68V9.68A.7.7 0 0 1 9.68 9",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }