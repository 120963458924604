import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "m16.7 9.8-8.74 9.38a.62.62 0 0 1-1.07-.55l1.14-5.73-4.5-1.69a.62.62 0 0 1-.24-1.01L12.04.82a.63.63 0 0 1 1.07.55L11.97 7.1l4.5 1.69a.63.63 0 0 1 .24 1.01Z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }