<script setup lang="ts">
import { supervisedServerQuery } from "@/api/servers.endpoints";
import { queryClient } from "@/queryClient";
import { useQuery } from "@tanstack/vue-query";
import { useRoute, type RouteLocationNormalizedLoaded } from "vue-router";
import type { RouteLocationNormalized } from "vue-router/auto";
import ServerPrivateSection from "@/components/servers/ServerPrivateSection.vue";
import DeleteServerSection from "@/components/servers/DeleteServerSection.vue";
import ServerSettingsSection from "@/components/servers/ServerSettingsSection.vue";
import ServerIconUploadField from "@/components/servers/ServerIconUploadField.vue";
import ServerBannerUploadField from "@/components/servers/ServerBannerUploadField.vue";
import ServerPageHeader from "@/components/servers/ServerPageHeader.vue";
import ServerBanStatusSection from "@/components/servers/ServerBanStatusSection.vue";

definePage({
	// @ts-expect-error
	beforeEnter: async (
		to: RouteLocationNormalized<"//admin/servers/[server_id]">,
	) => {
		await queryClient.ensureQueryData(
			supervisedServerQuery(to.params.server_id),
		);
	},
});

const route =
	useRoute() as RouteLocationNormalizedLoaded<"//admin/servers/[server_id]">;

const { data: server } = useQuery(
	supervisedServerQuery(route.params.server_id),
);
</script>

<template>
	<ServerPageHeader
		v-if="server"
		access-type="admin"
		:owner-id="server.user_id"
		:server-id="route.params.server_id"
	/>
	<div v-if="server" class="px-6 pb-16 pt-8 sm:px-10">
		<div class="mx-auto flex max-w-4xl flex-col gap-6">
			<ServerPrivateSection
				access-type="admin"
				:server-id="route.params.server_id"
			/>
			<ServerSettingsSection
				access-type="admin"
				:server-id="route.params.server_id"
				:owner-id="server.user_id"
			/>
			<div class="flex flex-col divide-y divide-white/15">
				<ServerIconUploadField
					access-type="admin"
					:server-id="route.params.server_id"
					:owner-id="server.user_id"
					name="icon"
					class="py-6"
				/>
				<ServerBannerUploadField
					access-type="admin"
					:server-id="route.params.server_id"
					:owner-id="server.user_id"
					name="banner"
					class="py-6"
				/>
			</div>
			<ServerBanStatusSection :server-id="route.params.server_id" />
			<DeleteServerSection
				access-type="admin"
				:server-id="route.params.server_id"
				:owner-id="server.user_id"
			/>
		</div>
	</div>
</template>
