<script setup lang="ts">
import { AvatarFallback, type AvatarFallbackProps } from "radix-vue";
import { type ClassNameValue, twMerge } from "tailwind-merge";
import { computed } from "vue";

const props = defineProps<AvatarFallbackProps & { class?: ClassNameValue }>();

const classes = computed(() => {
	return twMerge(
		"flex size-full items-center justify-center text-center font-medium",
		props.class,
	);
});
</script>

<template>
	<AvatarFallback v-bind="{ ...props, class: classes }">
		<slot />
	</AvatarFallback>
</template>
