import { createRouter, createWebHistory } from "vue-router";
import type { User } from "@/api/users.types";
import { queryClient } from "@/queryClient";
import { routes, handleHotUpdate } from "vue-router/auto-routes";
import { FetchError } from "@/utils";
import { currentUserQuery } from "@/api/users.endpoints";
import "vue-router";

declare module "vue-router" {
	interface RouteMeta {
		requiresAuth?: boolean;
		requiresAdminPrivileges?: boolean;
	}
}

export const router = createRouter({
	history: createWebHistory(),
	scrollBehavior: (to, from) => {
		if (to.path !== from.path) {
			document
				.querySelector("main [data-radix-scroll-area-viewport]")
				?.scrollTo({
					top: 0,
					left: 0,
					behavior: "instant",
				});
		}
	},
	routes,
});

if (import.meta.hot) {
	handleHotUpdate(router);
}

export class RouteAccessRestrictedError extends Error {
	override name = "RouteAccessRestrictedError";

	constructor(message: string) {
		super(message);
	}
}

router.beforeEach(async (to) => {
	if (to.meta.requiresAuth) {
		let me: User | null = null;

		try {
			me = await queryClient.ensureQueryData(currentUserQuery);
		} catch (error) {
			if (error instanceof FetchError && error.response.status === 403) {
				return { path: "/login" };
			}
			throw error;
		}

		if (
			to.meta.requiresAdminPrivileges &&
			!me.benefits.find((benefit) => benefit.key === "ADMIN")
		) {
			throw new RouteAccessRestrictedError(
				"This route can only be accessed by users with administrative privileges.",
			);
		}
	}

	return true;
});
