<script setup lang="ts">
import { currentUserQuery } from "@/api/users.endpoints";
import { useQuery } from "@tanstack/vue-query";
import BanIcon from "@/icons/ban.svg?component";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { getDiscordAvatarUrl } from "@/api/users.utils";

const { data: me } = useQuery(currentUserQuery);
</script>

<template>
	<div v-if="me && me.banned" class="fixed top-0 z-20 flex w-full p-3">
		<div
			class="mx-auto flex max-w-2xl flex-1 items-center justify-between gap-3 overflow-hidden rounded-md border border-stone-500 bg-stone-700 p-3"
		>
			<div class="flex min-w-0 items-center gap-2">
				<Avatar class="size-8">
					<AvatarImage :src="getDiscordAvatarUrl(me.discord) || ''" />
					<AvatarFallback class="text-sm">
						{{ me.discord.username[0]!.toUpperCase() }}
					</AvatarFallback>
				</Avatar>
				<span class="truncate text-sm font-medium">
					{{ me.discord.username }}
				</span>
			</div>
		</div>
	</div>
	<div v-if="me && me.banned" class="relative flex min-h-dvh px-6">
		<div
			class="mx-auto flex max-w-2xl flex-1 flex-col items-start justify-center gap-6"
		>
			<div class="rounded-full bg-red-400/15 p-2 ring-8 ring-red-400/5">
				<BanIcon class="size-8 text-red-400" />
			</div>
			<div class="flex flex-col gap-3">
				<h2 class="text-3xl font-semibold tracking-tight md:text-4xl">
					Sorry, your account is banned
				</h2>
				<p class="leading-normal text-white/75">
					Curious about your ban? Reach out to
					<a href="mailto:banappeal@alt-mp.com" class="text-white underline">
						banappeal@alt-mp.com
					</a>
					for insights and potential resolution.
				</p>
				<div
					v-if="me.ban_reason"
					class="mt-3 flex flex-col overflow-hidden rounded-md bg-red-400/15 p-3"
				>
					<span class="mb-0.5 text-xs text-red-200/75"> Ban reason </span>
					<p class="text-sm text-red-200">{{ me.ban_reason }}</p>
				</div>
			</div>
		</div>
	</div>
</template>
