import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M7.5 11.7h8.3L13 7.9l-2 2.5-1.3-1.6zM6.7 15q-.7 0-1.2-.5T5 13.3v-10q0-.6.5-1.1t1.2-.5h10q.6 0 1.1.5t.5 1.1v10q0 .8-.5 1.2-.5.5-1.1.5zm0-1.7h10v-10h-10zm-3.4 5q-.6 0-1.1-.5t-.5-1.1V5.8q0-.7.8-.8.8.1.8.8v10.9h10.9q.7 0 .8.8-.1.8-.8.8zm3.4-15h10v10h-10z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }