<script setup lang="ts">
import {
	CreateServerDialog,
	CreateServerGroupDialog,
	CreateNewDropdownMenu,
} from "@/components/create-new";
import { currentUserQuery } from "@/api/users.endpoints";
import ServerCard from "@/components/server-card/ServerCard.vue";
import { SearchInput } from "@/components/ui/search-input";
import { ChangeUserLimitsPopover } from "@/components/change-user-limits-popover";
import { SERVER_FILTER_CONTEXT, useMyGroupedServers } from "@/api/server.utils";
import { useQuery } from "@tanstack/vue-query";
import { useUserBenefit } from "@/api/users.utils";
import { ServerGroupHeader } from "@/components/server-group-header";
import { useThrottleFn } from "@vueuse/core";
import { computed, provide, ref } from "vue";
import { Spinner } from "@/components/ui/spinner";
import PlusIcon from "@/icons/plus.svg?component";
import { Button } from "@/components/ui/button";
import ServersOutlineIcon from "@/icons/servers-outline.svg?component";
import { ManagementHeader } from "@/components/management-header";
import { useRouteQuery } from "@vueuse/router";
import NotFoundIcon from "@/icons/not-found.svg?component";

definePage({ alias: "/" });

const search = useRouteQuery<string>("search", "");
const setSearchWithThrottle = useThrottleFn(
	(newValue: string) => (search.value = newValue),
	250,
	true,
);

provide(SERVER_FILTER_CONTEXT, { search });

const { data: me } = useQuery(currentUserQuery);
const myAdminBenefit = useUserBenefit(me, "ADMIN");

const { data: groupedServers, isLoading: isLoadingGroupedServers } =
	useMyGroupedServers();

const filteredServers = computed(() => {
	if (!groupedServers.value) return undefined;
	if (!search.value) return groupedServers.value;

	const filterValue = search.value.toLowerCase();

	const filteredServers = groupedServers.value.servers.filter((server) => {
		return server.name.toLowerCase().includes(filterValue);
	});
	const filteredGroups = groupedServers.value.groups
		.map((group) => {
			if (group.name.toLowerCase().includes(filterValue)) return group;
			const servers = group.servers.filter((server) =>
				server.name.toLowerCase().includes(filterValue),
			);
			if (servers.length) {
				return { ...group, servers };
			}
			return null;
		})
		.filter((group) => group !== null);
	const filteredManagedServers = groupedServers.value.managements
		.map((management) => {
			if (management.username.toLowerCase().includes(filterValue))
				return management;

			const servers = management.servers.filter((server) =>
				server.name.toLowerCase().includes(filterValue),
			);
			if (servers.length) {
				return { ...management, servers };
			}
			return null;
		})
		.filter((management) => management !== null);

	return {
		servers: filteredServers,
		groups: filteredGroups,
		managements: filteredManagedServers,
	};
});

const hasAnyServersToShow = computed(() => {
	if (!groupedServers.value) return false;
	return (
		groupedServers.value.servers.length > 0 ||
		groupedServers.value.groups.length > 0 ||
		groupedServers.value.managements.length > 0
	);
});

const hasResultsAfterFiltering = computed(() => {
	if (!filteredServers.value) return false;
	return (
		filteredServers.value.servers.length > 0 ||
		filteredServers.value.groups.length > 0 ||
		filteredServers.value.managements.length > 0
	);
});

const isCreateServerDialogOpen = ref(false);
const isCreateServerGroupDialogOpen = ref(false);
</script>

<template>
	<CreateServerDialog v-model:open="isCreateServerDialogOpen" />
	<CreateServerGroupDialog v-model:open="isCreateServerGroupDialogOpen" />

	<div
		v-if="me"
		class="border-b border-white/10 bg-gradient-to-b from-stone-600/90 px-6 py-8 sm:px-10 sm:py-10"
	>
		<div
			class="mx-auto flex max-w-7xl flex-col justify-between gap-x-8 gap-y-3 sm:flex-row"
		>
			<h2 class="text-3xl font-medium leading-10 tracking-tight sm:text-4xl">
				Servers
			</h2>
			<div class="flex gap-2">
				<SearchInput
					:model-value="search"
					@update:model-value="setSearchWithThrottle"
					placeholder="Search..."
					:disabled="!hasAnyServersToShow"
					class="w-full text-sm"
				/>
				<CreateNewDropdownMenu
					@select="
						(item) => {
							if (item === 'server') {
								isCreateServerDialogOpen = true;
							} else if (item === 'group') {
								isCreateServerGroupDialogOpen = true;
							}
						}
					"
				/>
				<ChangeUserLimitsPopover v-if="myAdminBenefit" :user-id="me.id" />
			</div>
		</div>
	</div>

	<div v-if="me" class="px-6 py-8 sm:px-10 sm:py-10">
		<div class="mx-auto flex max-w-7xl flex-col">
			<div
				v-if="isLoadingGroupedServers"
				class="flex min-h-40 w-full flex-col items-center justify-center rounded-lg border border-white/5 bg-stone-800 py-2"
			>
				<Spinner class="mb-3 size-6 text-white/50" />
				<span class="text-sm text-white/60">Loading servers...</span>
			</div>
			<div
				class="relative flex w-full flex-col items-center overflow-hidden rounded-xl border border-white/10 bg-gradient-to-b from-stone-800 px-5 py-10 text-center lg:px-10"
				v-else-if="!hasAnyServersToShow"
			>
				<div
					class="absolute inset-0 -z-[1] size-full"
					:style="{
						backgroundSize: '32px 32px',
						backgroundImage:
							'linear-gradient(#2D2D2D 1px, transparent 1px), linear-gradient(to right, #2D2D2D 1px, transparent 1px), linear-gradient(#121212, #121212)',
						maskImage: 'linear-gradient(0deg,#1e1e1e00,#1e1e1ecc)',
					}"
				></div>
				<ServersOutlineIcon class="mb-4 size-16 stroke-[3] text-stone-300" />
				<span class="mb-2 text-balance text-xl font-semibold text-white">
					You don't have any servers yet
				</span>
				<p class="max-w-md text-pretty text-sm leading-normal text-white/60">
					A "server" in alt:V Server Manager is a master list entry that is
					identified by a private token.
				</p>
				<Button
					size="sm"
					class="mt-5 w-full pr-3 font-medium xs:w-auto"
					@click="isCreateServerDialogOpen = true"
				>
					New server
					<PlusIcon class="size-5 stroke-[1.5]" />
				</Button>
			</div>
			<div
				v-else-if="!hasResultsAfterFiltering"
				class="relative flex w-full flex-col items-center overflow-hidden rounded-xl border border-white/10 bg-gradient-to-b from-stone-800 px-5 py-10 text-center lg:px-10"
			>
				<div
					class="absolute inset-0 -z-[1] size-full"
					:style="{
						backgroundSize: '32px 32px',
						backgroundImage:
							'linear-gradient(#2D2D2D 1px, transparent 1px), linear-gradient(to right, #2D2D2D 1px, transparent 1px), linear-gradient(#121212, #121212)',
						maskImage: 'linear-gradient(0deg,#1e1e1e00,#1e1e1ecc)',
					}"
				></div>
				<NotFoundIcon class="mb-3 h-12 w-12 stroke-[2.5] text-stone-300" />
				<span class="mb-1 text-lg font-medium">
					No results for
					<span class="text-green-200">"{{ search }}"</span>
				</span>
				<span class="max-w-sm text-sm text-white/60">
					Couldn't find server by the specifing filter. Try searching for
					something else.
				</span>
			</div>
			<div
				v-else-if="filteredServers"
				class="flex flex-col gap-8 divide-y divide-white/15 [&>*:not(:first-child)]:pt-5"
			>
				<ul
					v-if="filteredServers.servers.length"
					class="grid gap-4 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4"
				>
					<ServerCard
						v-for="server of filteredServers.servers"
						:server="server"
						:key="server.public_id"
						access-type="owner"
					/>
				</ul>
				<div
					v-for="group of filteredServers.groups"
					class="flex flex-col gap-7"
					:key="group.id"
				>
					<ServerGroupHeader :group="group" access-type="owner" />
					<ul
						v-if="group.servers.length"
						class="grid gap-4 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4"
					>
						<ServerCard
							v-for="server in group.servers"
							:key="server.public_id"
							:server="server"
							access-type="owner"
						/>
					</ul>
				</div>
				<div
					v-for="management of filteredServers.managements"
					class="flex flex-col gap-7"
					:key="management.id"
				>
					<ManagementHeader
						:management="management"
						:servers="management.servers"
					/>
					<ul
						v-if="management.servers.length"
						class="grid gap-4 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4"
					>
						<ServerCard
							v-for="server in management.servers"
							:key="server.public_id"
							:server="server"
							access-type="manager"
						/>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>
