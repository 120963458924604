<script setup lang="ts">
import AltvLogo from "@/icons/altv.svg?component";
import { AvatarImage, AvatarRoot, AvatarFallback } from "radix-vue";
import { Button } from "@/components/ui/button";
import DiscordLogo from "@/icons/discord.svg?component";
import { useQuery, useQueryClient } from "@tanstack/vue-query";
import { useLocalStorage } from "@vueuse/core";
import { discordAuthURLQuery } from "@/api/auth";
import { push } from "notivue";
import { currentUserQuery } from "@/api/users.endpoints";
import LoaderIcon from "@/icons/loader.svg?component";
import { watch } from "vue";

definePage({
	beforeEnter: async () => {
		const queryClient = useQueryClient();

		const me = await queryClient
			.ensureQueryData({ ...currentUserQuery, retry: false })
			.catch(() => null);

		if (me) {
			return { path: "/" };
		}

		return true;
	},
});

const queryClient = useQueryClient();
queryClient.prefetchQuery(discordAuthURLQuery);

const storedState = useLocalStorage<string | null>("state", null);

const { isPending, error } = useQuery(discordAuthURLQuery);

watch(error, () => {
	if (error.value) {
		push.error({
			title: "Failed to request authorization URL",
			message:
				error.value instanceof Error ? error.value.message : "Unknown error",
		});
	}
});

async function redirectToDiscordAuth() {
	try {
		const authURL = new URL(
			await queryClient.ensureQueryData(discordAuthURLQuery),
		);

		const state = crypto.randomUUID();
		authURL.searchParams.set("state", state);
		storedState.value = state;

		location.replace(authURL);
	} catch (error) {
		push.error({
			title: "Failed to request authorization URL",
			message: error instanceof Error ? error.message : "Unknown error",
		});
	}
}
</script>

<template>
	<section class="flex h-dvh w-full items-center overflow-hidden">
		<AvatarRoot
			class="fixed left-0 top-0 -z-10 h-dvh w-full select-none lg:relative lg:w-[37%] xl:w-[45%]"
		>
			<AvatarImage
				class="size-full object-cover opacity-25 lg:opacity-90"
				src="/img/login_page_bg.webp"
				alt="Vibrant GTA V server wallpaper featuring a sleek green car parked prominently in the foreground"
			/>
			<AvatarFallback
				style="background-color: #615e5c"
				class="size-full animate-pulse"
			/>
		</AvatarRoot>
		<div
			class="container flex flex-1 flex-col items-start px-6 md:px-10 lg:max-w-none lg:px-20"
		>
			<AltvLogo
				aria-label="alt:V logo"
				class="mb-8 size-[72px] shrink-0 text-green-500 sm:size-20"
			/>
			<h1
				class="mb-[0.4em] text-5xl font-medium leading-[1.1] tracking-[-0.035em] sm:text-6xl"
			>
				Server Manager
			</h1>
			<p
				class="max-w-2xl text-lg leading-normal text-white/70 sm:text-xl sm:leading-normal"
			>
				Take control of your alt:V server. Activate server benefits, generate
				tokens, and seamlessly manage various server functions.
			</p>
			<Button
				size="2xl"
				class="mt-10 w-full sm:w-auto"
				@click="redirectToDiscordAuth"
				:disabled="isPending || error"
			>
				<LoaderIcon
					v-if="isPending"
					class="size-6 animate-spin stroke-[1.75]"
				/>
				<DiscordLogo v-else class="size-6" />
				Login with Discord
			</Button>
		</div>
	</section>
</template>
