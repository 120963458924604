import _definePage_default_0 from '/app/src/pages/index/admin/benefits/[benefit_key]/users/[user_id].vue?definePage&vue&lang.tsx'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/app/src/pages/index/admin/protection-issues/[issue_id]/users/[user_id].vue?definePage&vue&lang.tsx'
import _definePage_default_3 from '/app/src/pages/index/admin/servers/[server_id].vue?definePage&vue&lang.tsx'
import _definePage_default_4 from '/app/src/pages/index/admin/users/[user_id]/servers.vue?definePage&vue&lang.tsx'
import _definePage_default_5 from '/app/src/pages/index/admin/users/[user_id].vue?definePage&vue&lang.tsx'
import _definePage_default_6 from '/app/src/pages/index/admin.vue?definePage&vue&lang.tsx'
import _definePage_default_7 from '/app/src/pages/index/benefits/[benefit_key].vue?definePage&vue&lang.tsx'
import _definePage_default_8 from '/app/src/pages/index/management/[user_id]/servers/[server_id].vue?definePage&vue&lang.tsx'
import _definePage_default_9 from '/app/src/pages/index/servers/index.vue?definePage&vue&lang.tsx'
import _definePage_default_10 from '/app/src/pages/index/servers/[server_id].vue?definePage&vue&lang.tsx'
import _definePage_default_11 from '/app/src/pages/index/settings/managers.vue?definePage&vue&lang.tsx'
import _definePage_default_12 from '/app/src/pages/index.vue?definePage&vue&lang.tsx'
import _definePage_default_13 from '/app/src/pages/login/index.vue?definePage&vue&lang.tsx'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: '/',
    component: () => import('/app/src/pages/index.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'admin',
        name: '//admin',
        component: () => import('/app/src/pages/index/admin.vue'),
        children: [
          {
            path: 'benefits',
            /* internal name: '//admin/benefits' */
            /* no component */
            children: [
              {
                path: ':benefit_key',
                /* internal name: '//admin/benefits/[benefit_key]' */
                /* no component */
                children: [
                  {
                    path: 'users',
                    /* internal name: '//admin/benefits/[benefit_key]/users' */
                    /* no component */
                    children: [
  _mergeRouteRecord(
                      {
                        path: ':user_id',
                        name: '//admin/benefits/[benefit_key]/users/[user_id]',
                        component: () => import('/app/src/pages/index/admin/benefits/[benefit_key]/users/[user_id].vue'),
                        /* no children */
                      },
  _definePage_default_0
  )
                    ],
                  }
                ],
              }
            ],
          },
          {
            path: 'protection-issues',
            /* internal name: '//admin/protection-issues' */
            /* no component */
            children: [
              {
                path: '',
                name: '//admin/protection-issues/',
                component: () => import('/app/src/pages/index/admin/protection-issues/index.vue'),
                /* no children */
              },
              {
                path: ':issue_id',
                /* internal name: '//admin/protection-issues/[issue_id]' */
                /* no component */
                children: [
                  {
                    path: 'users',
                    /* internal name: '//admin/protection-issues/[issue_id]/users' */
                    /* no component */
                    children: [
  _mergeRouteRecord(
                      {
                        path: ':user_id',
                        name: '//admin/protection-issues/[issue_id]/users/[user_id]',
                        component: () => import('/app/src/pages/index/admin/protection-issues/[issue_id]/users/[user_id].vue'),
                        /* no children */
                      },
  _definePage_default_2
  )
                    ],
                  }
                ],
              }
            ],
          },
          {
            path: 'servers',
            /* internal name: '//admin/servers' */
            /* no component */
            children: [
              {
                path: '',
                name: '//admin/servers/',
                component: () => import('/app/src/pages/index/admin/servers/index.vue'),
                /* no children */
              },
  _mergeRouteRecord(
              {
                path: ':server_id',
                name: '//admin/servers/[server_id]',
                component: () => import('/app/src/pages/index/admin/servers/[server_id].vue'),
                /* no children */
              },
  _definePage_default_3
  )
            ],
          },
          {
            path: 'users',
            /* internal name: '//admin/users' */
            /* no component */
            children: [
              {
                path: '',
                name: '//admin/users/',
                component: () => import('/app/src/pages/index/admin/users/index.vue'),
                /* no children */
              },
  _mergeRouteRecord(
              {
                path: ':user_id',
                name: '//admin/users/[user_id]',
                component: () => import('/app/src/pages/index/admin/users/[user_id].vue'),
                children: [
                  {
                    path: 'benefits',
                    name: '//admin/users/[user_id]/benefits',
                    component: () => import('/app/src/pages/index/admin/users/[user_id]/benefits.vue'),
                    /* no children */
                  },
  _mergeRouteRecord(
                  {
                    path: 'servers',
                    name: '//admin/users/[user_id]/servers',
                    component: () => import('/app/src/pages/index/admin/users/[user_id]/servers.vue'),
                    /* no children */
                  },
  _definePage_default_4
  ),
                  {
                    path: 'settings',
                    name: '//admin/users/[user_id]/settings',
                    component: () => import('/app/src/pages/index/admin/users/[user_id]/settings.vue'),
                    /* no children */
                  }
                ],
              },
  _definePage_default_5
  )
            ],
          }
        ],
      },
  _definePage_default_6
  ),
      {
        path: 'benefits',
        /* internal name: '//benefits' */
        /* no component */
        children: [
          {
            path: '',
            name: '//benefits/',
            component: () => import('/app/src/pages/index/benefits/index.vue'),
            /* no children */
          },
  _mergeRouteRecord(
          {
            path: ':benefit_key',
            name: '//benefits/[benefit_key]',
            component: () => import('/app/src/pages/index/benefits/[benefit_key].vue'),
            /* no children */
          },
  _definePage_default_7
  )
        ],
      },
      {
        path: 'management',
        /* internal name: '//management' */
        /* no component */
        children: [
          {
            path: ':user_id',
            /* internal name: '//management/[user_id]' */
            /* no component */
            children: [
              {
                path: 'servers',
                /* internal name: '//management/[user_id]/servers' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: ':server_id',
                    name: '//management/[user_id]/servers/[server_id]',
                    component: () => import('/app/src/pages/index/management/[user_id]/servers/[server_id].vue'),
                    /* no children */
                  },
  _definePage_default_8
  )
                ],
              }
            ],
          }
        ],
      },
      {
        path: 'servers',
        /* internal name: '//servers' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '//servers/',
            component: () => import('/app/src/pages/index/servers/index.vue'),
            /* no children */
          },
  _definePage_default_9
  ),
  _mergeRouteRecord(
          {
            path: ':server_id',
            name: '//servers/[server_id]',
            component: () => import('/app/src/pages/index/servers/[server_id].vue'),
            /* no children */
          },
  _definePage_default_10
  )
        ],
      },
      {
        path: 'settings',
        name: '//settings',
        component: () => import('/app/src/pages/index/settings.vue'),
        children: [
          {
            path: 'delete-account',
            name: '//settings/delete-account',
            component: () => import('/app/src/pages/index/settings/delete-account.vue'),
            /* no children */
          },
  _mergeRouteRecord(
          {
            path: 'managers',
            name: '//settings/managers',
            component: () => import('/app/src/pages/index/settings/managers.vue'),
            /* no children */
          },
  _definePage_default_11
  ),
          {
            path: 'transfers',
            name: '//settings/transfers',
            component: () => import('/app/src/pages/index/settings/transfers.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  _definePage_default_12
  ),
  {
    path: '/login',
    /* internal name: '/login' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: '/login/',
        component: () => import('/app/src/pages/login/index.vue'),
        /* no children */
      },
  _definePage_default_13
  ),
      {
        path: 'discord',
        /* internal name: '/login/discord' */
        /* no component */
        children: [
          {
            path: 'callback',
            name: '/login/discord/callback',
            component: () => import('/app/src/pages/login/discord/callback.vue'),
            /* no children */
          }
        ],
      }
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

