import type { UserDiscord } from "./users.types";

export const BENEFIT_KEYS = {
	/**
	 * Set an image that will be displayed in the alt:V client when you open the server modal.
	 *
	 * *Requires at least "Ruby Altruist" tier.*
	 */
	CUSTOM_BANNER: "CUSTOM_BANNER",
	/**
	 * By default will “promote” all of your servers and server groups by moving
	 * them to the top of the server list. (This can be disabled by the user)
	 *
	 * *Requires at least "Diamond Altruist" tier.*
	 */
	PROMOTED: "PROMOTED",
	/**
	 * Gives ability to create custom version of alt:V client where only your servers are visible. You will be able to change the images, colors and set your own rss feed for news on the main page.
	 *
	 * *Requires at least "Diamond Altruist" tier.*
	 */
	CUSTOM_LAUNCHER: "CUSTOM_LAUNCHER",
	/**
	 * Ability to set your own unique identifier for your servers, instead of
	 * the random one generated by default. This identifier is used in URLs to access the server (e.g. altv.run/my_server).
	 *
	 * *Requires at least "Gold Altruist" tier.*
	 */
	CUSTOM_VANITY_URL: "CUSTOM_VANITY_URL",
	/**
	 * Access to all administrator endpoints.
	 *
	 * *This benefit can't be received by purchasing a tier.*
	 */
	ADMIN: "ADMIN",
	/**
	 * Access to the Cloud Auth feature (unique identification solution developed by alt:V).
	 *
	 * *Requires at least "Silver Altruist" tier.*
	 */
	CLIENT_CLOUD_AUTH: "CLIENT_CLOUD_AUTH",
	/**
	 * Ability to set a custom background image for the server join screen.
	 *
	 * *This benefit can't be received by purchasing a tier.*
	 */
	CUSTOM_JOIN_BACKGROUND: "CUSTOM_JOIN_BACKGROUND",
	/**
	 * Ability to use your own hosted UI for custom launcher. Works only with the `CUSTOM_LAUNCHER` benefit.
	 *
	 * *This benefit can't be received by purchasing a tier.*
	 */
	CUSTOM_UI_URL: "CUSTOM_UI_URL",
	/**
	 * Gives the ability to create and manage server groups.
	 * This feature is helpful for large projects with multiple servers.
	 *
	 * *Requires at least "Diamond Altruist" tier.*
	 */
	MASTERLIST_GROUPS: "MASTERLIST_GROUPS",
	/**
	 * Allows to set custom icon and banner images for your servers.
	 *
	 * *Requires at least "Platinum Altruist" tier.*
	 */
	MASTERLIST_TOKEN_CARD_ICONS: "MASTERLIST_TOKEN_CARD_ICONS",
} as const;
export type BenefitKey = keyof typeof BENEFIT_KEYS;

interface BenefitFiles extends Record<BenefitWithFilesKey, string> {
	CUSTOM_LAUNCHER:
		| "launcherBackground"
		| "logo"
		| "uiBackground"
		| "installerBackground"
		| "customUiImageAppIcon";
	CUSTOM_BANNER: "banner";
	CUSTOM_JOIN_BACKGROUND: "customJoinBanner";
	MASTERLIST_TOKEN_CARD_ICONS: `${string}/icon` | `${string}/banner`;
	MASTERLIST_GROUPS: `${string}/icon`;
}

export type BenefitFileKey = BenefitFiles[keyof BenefitFiles];

export const BENEFITS_WITH_FILES = [
	BENEFIT_KEYS.CUSTOM_BANNER,
	BENEFIT_KEYS.CUSTOM_LAUNCHER,
	BENEFIT_KEYS.CUSTOM_JOIN_BACKGROUND,
	BENEFIT_KEYS.MASTERLIST_TOKEN_CARD_ICONS,
	BENEFIT_KEYS.MASTERLIST_GROUPS,
] as const;
export type BenefitWithFilesKey = (typeof BENEFITS_WITH_FILES)[number];

export const BENEFITS_WITH_SETTINGS = [BENEFIT_KEYS.CUSTOM_LAUNCHER];
export type BenefitWithSettingsKey = (typeof BENEFITS_WITH_SETTINGS)[number];

export interface Benefit {
	id: string;
	key: BenefitKey;
	description: string;
	tier_id: string;
	settings: Record<string, unknown>;
}

export interface UserBenefit {
	key: BenefitKey;
	description: string;
	settings: Record<string, unknown> | null;
	manual_added: boolean;
	expires_at: string | null;
	files: UserBenefitFile[];
}

export interface UserBenefitFile {
	id: string;
	benefit_id: string;
	created_at: string;
	updated_at: string;
	user_id: string;
	url: string;
	key: string;
}

export interface Tier {
	id: string;
	name: string;
	benefits: Benefit[];
	discord_roles_ids: string[];
	child_tier_id: string | null;
	parent_tier_id: string | null;
	image_url: string | null;
	price_cents: number;
}

export interface TierGiftRecepient {
	id: string;
	discord: UserDiscord;
}

export interface TierGiftSender {
	id: string;
	discord: UserDiscord;
}

export interface UserOwnedTier {
	id: string;
	name: string;
	image_url: string | null;
	child_tier_id: string | null;
	parent_tier_id: string | null;
	gifted_to: TierGiftRecepient;
	price_cents: number;
}

export interface UserActiveTier {
	id: string;
	name: string;
	image_url: string | null;
	child_tier_id: string | null;
	parent_tier_id: string | null;
	gifted: boolean;
	price_cents: number;
}

export interface GiftedTier {
	id: string;
	name: string;
	image_url: string | null;
	price_cents: number;
}

export interface TierGift {
	id: string;
	tier: GiftedTier;
	created_at: string;
	from_user_id: string;
	from_user: TierGiftSender;
	to_user_id: string;
	to_user: TierGiftRecepient;
}
