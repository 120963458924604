<script setup lang="ts">
import { userQuery } from "@/api/users.endpoints";
import { queryClient } from "@/queryClient";
import { useQuery } from "@tanstack/vue-query";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
	Tabs,
	TabsIndicator,
	TabsList,
	TabsTrigger,
} from "@/components/ui/tabs";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import {
	useRoute,
	type RouteLocationNormalizedLoaded,
	type RouteLocationNormalized,
} from "vue-router";
import { getDiscordAvatarUrl } from "@/api/users.utils";
import dayjs from "dayjs";
import CalendarEventIcon from "@/icons/calendar-event.svg?component";
import PatreonLogo from "@/icons/patreon.svg?component";
import BanIcon from "@/icons/ban.svg?component";

definePage({
	// @ts-expect-error
	beforeEnter: async (
		to: RouteLocationNormalized<"//admin/users/[user_id]">,
	) => {
		await queryClient.ensureQueryData(userQuery(to.params.user_id));
	},
});

const route =
	useRoute() as RouteLocationNormalizedLoaded<"//admin/users/[user_id]">;

const { data: user } = useQuery(userQuery(route.params.user_id));
</script>

<template>
	<section
		v-if="user"
		class="relative border-b border-white/10 bg-gradient-to-b from-stone-600/90 px-6 pt-6 sm:px-10"
	>
		<div class="mx-auto flex min-w-0 max-w-5xl flex-col items-start gap-4">
			<div class="relative">
				<Avatar class="size-16">
					<AvatarImage
						:src="getDiscordAvatarUrl(user.discord) || ''"
						height="64"
						width="64"
					/>
					<AvatarFallback class="text-2xl">
						{{ user.discord.username.slice(0, 2).toUpperCase() }}
					</AvatarFallback>
				</Avatar>
				<Tooltip v-if="user.banned">
					<TooltipTrigger class="absolute bottom-0 right-0">
						<BanIcon class="size-5 text-red-400" />
					</TooltipTrigger>
					<TooltipContent> Banned </TooltipContent>
				</Tooltip>
			</div>
			<div class="flex flex-col items-start">
				<h1
					class="text-[clamp(24px,3vw,36px)] font-medium leading-tight tracking-tight sm:truncate"
				>
					{{ user.discord.username }}
				</h1>
				<div class="mt-1.5 inline-flex flex-wrap items-center gap-y-0.5">
					<span
						v-if="user.patreon"
						class="inline-flex items-center text-white/60"
					>
						<PatreonLogo class="mr-1.5 size-4" />
						<span class="truncate text-sm">{{ user.patreon.username }}</span>
						<span class="mx-2 text-sm text-white/50">&bull;</span>
					</span>
					<span class="inline-flex items-center text-white/60">
						<CalendarEventIcon class="mr-1.5 size-4" />
						<span class="truncate text-sm">
							Member since
							{{ dayjs(user.created_at).format("MMMM D, YYYY") }}
						</span>
					</span>
				</div>
			</div>
			<div
				v-if="user.banned && user.ban_reason"
				class="flex w-full flex-col rounded-md bg-red-400/15 p-3"
			>
				<span class="mb-0.5 text-xs text-red-200/75">Ban reason</span>
				<p class="text-sm text-red-200">{{ user.ban_reason }}</p>
			</div>
			<Tabs
				:default-value="route.path.split('/')[4] || 'servers'"
				class="-mt-2"
			>
				<TabsList class="border-b-0">
					<TabsIndicator />
					<TabsTrigger value="servers" as-child>
						<RouterLink :to="`/admin/users/${route.params.user_id}/servers`">
							Servers
						</RouterLink>
					</TabsTrigger>
					<TabsTrigger value="benefits" as-child>
						<RouterLink :to="`/admin/users/${route.params.user_id}/benefits`">
							Benefits
						</RouterLink>
					</TabsTrigger>
					<TabsTrigger value="settings" as-child>
						<RouterLink :to="`/admin/users/${route.params.user_id}/settings`">
							Settings
						</RouterLink>
					</TabsTrigger>
				</TabsList>
			</Tabs>
		</div>
	</section>
	<section v-if="user" class="relative px-6 pb-16 pt-8 sm:px-10">
		<div class="mx-auto flex max-w-5xl flex-col">
			<RouterView />
		</div>
	</section>
</template>
