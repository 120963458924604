import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 18 18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M8.18 2.09a1.69 1.69 0 0 1 2.23.55l.06.1 5.82 10.51a1.69 1.69 0 0 1-1.36 2.5H3.18a1.69 1.69 0 0 1-1.53-2.4l.05-.1 5.82-10.5c.15-.28.38-.51.66-.66ZM9 12a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5Zm0-6c-.39 0-.7.29-.75.66V10.6a.75.75 0 0 0 1.5 0V6.66A.75.75 0 0 0 9 6Z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }