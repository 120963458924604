import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M5.8 1h4.4q.8 0 1.4.6l2.8 2.8q.6.6.6 1.4v4.4q0 .8-.6 1.4l-2.8 2.8a2 2 0 0 1-1.4.6H5.8a2 2 0 0 1-1.4-.6l-2.8-2.8a2 2 0 0 1-.6-1.4V5.8q0-.8.6-1.4l2.8-2.8A2 2 0 0 1 5.8 1M4.5 6.8q-.5 0-.5.5v1.5q0 .4.5.4h7q.5 0 .5-.4V7.2q0-.4-.5-.5z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }