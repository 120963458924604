<script lang="ts">
import InfoCircleIcon from "@/icons/info-circle-outline.svg?component";
import AlertCircleIcon from "@/icons/alert-circle-outline.svg?component";
import CheckCircleIcon from "@/icons/check-circle-outline.svg?component";
import WarningSolidIcon from "@/icons/warning.svg?component";
import type { NotificationType } from "notivue";
import type { FunctionalComponent } from "vue";
import { cva } from "class-variance-authority";

const icons: Record<NotificationType, FunctionalComponent> = {
	info: InfoCircleIcon,
	error: AlertCircleIcon,
	warning: WarningSolidIcon,
	success: CheckCircleIcon,
	promise: () => {
		throw new Error("Not implemented");
	},
	"promise-resolve": () => {
		throw new Error("Not implemented");
	},
	"promise-reject": () => {
		throw new Error("Not implemented");
	},
};

const iconVariants = cva("size-5 flex-shrink-0 stroke-[1.6]", {
	variants: {
		type: {
			info: "text-stone-200",
			error: "text-red-400 saturate-[1.2]",
			warning: "text-orange-500",
			success: "text-green-300 saturate-[0.8]",
			promise: "",
			"promise-resolve": "",
			"promise-reject": "",
		},
	},
});
</script>

<script setup lang="ts">
import { type NotivueItem } from "notivue";
import type { Component } from "vue";
import CloseIcon from "@/icons/close.svg?component";

defineProps<{
	alert: NotivueItem<{ action?: Component }>;
}>();
</script>

<template>
	<div
		class="relative flex max-w-sm gap-x-2.5 overflow-hidden rounded-lg bg-stone-600 p-4 shadow-[0px_10px_20px_-4px_rgba(0,0,0,0.25),0px_4px_8px_-1px_rgba(0,0,0,0.2),0px_1px_0px_0px_rgba(255,255,255,0.06)_inset]"
	>
		<component
			:is="icons[alert.type]"
			:class="iconVariants({ type: alert.type })"
		/>
		<div class="flex flex-col items-start justify-start pr-8">
			<h4 class="text-sm font-medium leading-5">{{ alert.title }}</h4>
			<p
				class="mt-1 line-clamp-3 text-pretty text-sm text-white/60"
				v-if="alert.message"
			>
				{{ alert.message }}
			</p>
			<div v-if="alert.props.action" class="mt-2.5">
				<component :is="alert.props.action" />
			</div>
		</div>
		<button
			type="button"
			@click="alert.clear()"
			class="group absolute right-1 top-1 rounded p-2 outline-none ring-white/25 focus-visible:ring-2"
		>
			<CloseIcon
				aria-hidden="true"
				class="size-5 stroke-[1.5] text-white/50 transition-colors ease-out-quad group-hover:text-white/70"
			/>
		</button>
	</div>
</template>
