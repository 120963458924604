import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M19 14v-1.75c0-.2-.33-.42-.5-.5l-2.05-1.03a1 1 0 0 0-.9 0l-2.05 1.03c-.17.08-.5.3-.5.5V14c0 1.65.8 2.65 1.46 3.2.73.58 1.44.78 1.47.8h.13c.04-.02.75-.22 1.47-.8A4 4 0 0 0 19 14",
      fill: "currentColor"
    }, null, -1),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M2.18 15.36q1.34 1.3 3.24 1.3h6.15A6 6 0 0 1 11 14v-1.75c0-.6.24-1.04.4-1.28q.27-.36.48-.53.41-.33.73-.48l2.05-1.03a3 3 0 0 1 1.15-.3 5.6 5.6 0 0 0-1.68-3.6A5.6 5.6 0 0 0 10 3.33q-1.98 0-3.54 1.2a5.7 5.7 0 0 0-2.08 3.1A4.3 4.3 0 0 0 1.8 9.24a4.7 4.7 0 0 0-.98 2.9q0 1.9 1.35 3.2",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }