<script setup lang="ts">
import {
	ManagersSection,
	ReceivedManagementsSection,
} from "@/components/managers";

definePage({ alias: "/settings" });
</script>

<template>
	<ManagersSection />
	<ReceivedManagementsSection />
</template>
