<script setup lang="ts">
import { useMediaQuery } from "@vueuse/core";
import { ref } from "vue";
import TierPromotion from "@/components/layout/TierPromotion.vue";
import NavigationMenu from "@/components/layout/NavigationMenu.vue";
import { useQuery } from "@tanstack/vue-query";
import { currentUserQuery } from "@/api/users.endpoints";
import AltvLogo from "@/icons/altv.svg?component";
import { useUserAvatarUrl } from "@/api/users.utils";
import {
	ScrollArea,
	ScrollAreaViewport,
	ScrollAreaScrollbar,
} from "@/components/ui/scroll-area";
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
	BurgerDialog,
	BurgerDialogContent,
	BurgerDialogTrigger,
} from "@/components/ui/burger-dialog";
import DotsHorizontalIcon from "@/icons/dots-horizontal.svg?component";
import ProfileDropdownMenuContent from "@/components/layout/ProfileDropdownMenuContent.vue";

definePage({
	alias: "/",
	meta: { requiresAuth: true },
});

const { data: me } = useQuery(currentUserQuery);
const avatarUrl = useUserAvatarUrl(me);

const isDesktop = useMediaQuery("(min-width: 1024px)");

const isNavMenuOpen = ref(false);
const isProfileDropdownOpen = ref(false);
</script>

<template>
	<div v-if="me" class="relative flex h-dvh flex-col md:flex-row">
		<div
			class="fixed z-20 flex h-14 w-full items-center justify-between border-b border-white/10 bg-stone-900/75 px-5 backdrop-blur-[20px] backdrop-saturate-150 lg:relative lg:h-auto lg:shrink-0 lg:grow-0 lg:basis-72 lg:flex-col lg:gap-4 lg:border-b-0 lg:bg-transparent lg:p-3 xl:basis-80"
		>
			<div class="flex w-full lg:px-1 lg:pb-2">
				<RouterLink to="/" class="flex items-center py-0.5 lg:py-0">
					<AltvLogo class="mr-3 size-8 shrink-0 text-green-500" />
					<span
						class="whitespace-nowrap text-lg font-medium tracking-[-0.01em]"
					>
						Server Manager
					</span>
				</RouterLink>
			</div>

			<div class="inline-flex shrink-0 gap-2 lg:w-full lg:flex-col lg:gap-0">
				<BurgerDialog v-if="!isDesktop" v-model:open="isNavMenuOpen">
					<BurgerDialogTrigger />
					<BurgerDialogContent class="px-5 pb-5 pt-[4.5rem]">
						<div class="container flex h-full flex-col">
							<NavigationMenu class="flex-1" @select="isNavMenuOpen = false" />
							<TierPromotion :user-active-tier="me.active_tier" />
						</div>
					</BurgerDialogContent>
				</BurgerDialog>

				<DropdownMenu v-model:open="isProfileDropdownOpen" :modal="false">
					<DropdownMenuTrigger
						v-if="!isDesktop"
						class="shrink-0 rounded-full outline-none transition-shadow data-[state=active]:stroke-white/60"
					>
						<Avatar>
							<AvatarImage v-if="avatarUrl" :src="avatarUrl" />
							<AvatarFallback>
								{{ me.discord.username.slice(0, 2).toUpperCase() }}
							</AvatarFallback>
						</Avatar>
					</DropdownMenuTrigger>
					<div
						v-else
						class="group flex w-full items-center gap-x-3 rounded-full text-sm outline-none transition-shadow lg:rounded-none"
					>
						<Avatar>
							<AvatarImage v-if="avatarUrl" :src="avatarUrl" />
							<AvatarFallback>
								{{ me.discord.username.slice(0, 2).toUpperCase() }}
							</AvatarFallback>
						</Avatar>
						<span class="hidden w-full text-left font-medium lg:inline">
							{{ me.discord.username }}
						</span>
						<DropdownMenuTrigger
							@click.prevent.stop
							class="hidden rounded-md text-white/60 outline-none transition-shadow hover:bg-white/5 data-[state=open]:bg-white/5 data-[state=open]:text-white/60 lg:inline-flex lg:shrink-0 lg:p-1.5 lg:focus-within:bg-white/[.08] lg:focus-within:text-white/60"
						>
							<DotsHorizontalIcon class="size-5 rotate-90 stroke-[1.8]" />
						</DropdownMenuTrigger>
					</div>
					<DropdownMenuContent align="end" :side-offset="6">
						<ProfileDropdownMenuContent />
					</DropdownMenuContent>
				</DropdownMenu>
			</div>

			<aside class="flex w-full flex-1 flex-col" v-if="isDesktop">
				<NavigationMenu class="flex-1" @select="isNavMenuOpen = false" />
				<TierPromotion :user-active-tier="me.active_tier" />
			</aside>
		</div>
		<ScrollArea
			as="main"
			class="mt-14 w-full flex-1 [--scrollbar-size:10px] md:[--scrollbar-size:14px] lg:mt-0 lg:border-l lg:border-white/15"
		>
			<ScrollAreaViewport>
				<RouterView />
			</ScrollAreaViewport>
			<ScrollAreaScrollbar orientation="vertical" class="md:p-[3px]" />
		</ScrollArea>
	</div>
</template>
