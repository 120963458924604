import { queryOptions, useMutation, useQueryClient } from "@tanstack/vue-query";
import type {
	User,
	ManagementPermission,
	UserManagement,
	PublicUser,
	UserWithOwnedServers,
	UsersPagingObject,
} from "./users.types";
import { FetchError, createURLSearchParams } from "@/utils";
import { myServersQuery, supervisedServersQuery } from "./servers.endpoints";

export const currentUserQuery = queryOptions({
	queryKey: ["api", "auth", "me"],
	queryFn: async ({ signal }) => {
		const res = await fetch("/api/auth/me", { signal });
		if (!res.ok) {
			throw new FetchError(res);
		}
		const { data: user } = (await res.json()) as { data: User };
		return user;
	},
});

export function useInitiateSelfDeletionMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async () => {
			const res = await fetch("/api/auth/me/delete", { method: "POST" });
			if (!res.ok) {
				throw new FetchError(res);
			}
			const { data: deleteAt } = (await res.json()) as { data: string };
			return deleteAt;
		},
		onSuccess: (deleteAt) => {
			queryClient.setQueryData(currentUserQuery.queryKey, (me) => {
				if (!me) return me;
				return { ...me, delete_at: deleteAt };
			});
		},
	});
}

export function useAbortSelfDeletionMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async () => {
			const res = await fetch("/api/auth/me/delete/cancel", { method: "POST" });
			if (!res.ok) {
				throw new FetchError(res);
			}
		},
		onSuccess: () => {
			queryClient.setQueryData(currentUserQuery.queryKey, (me) => {
				if (!me) return me;
				return { ...me, delete_at: null };
			});
		},
	});
}

export interface GrantManagementPermissonsBody {
	userId: string;
	permissions: ManagementPermission[];
}

export function useGrantManagementPermissionsMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (body: GrantManagementPermissonsBody) => {
			const res = await fetch("/api/users/management", {
				method: "POST",
				body: JSON.stringify({
					user_id: body.userId,
					permissions: body.permissions,
				}),
				headers: { "Content-Type": "application/json" },
			});
			if (!res.ok) {
				throw new FetchError(res);
			}
			const { data: userManagement } = (await res.json()) as {
				data: UserManagement;
			};
			return userManagement;
		},
		onSuccess: (userManagement) => {
			queryClient.setQueryData(currentUserQuery.queryKey, (user) => {
				if (!user) return user;
				return {
					...user,
					gived_managements: [...user.gived_managements, userManagement],
				};
			});
		},
	});
}

export function useRevokeManagementPermissionsMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({ managerId }: { managerId: string }) => {
			const res = await fetch("/api/users/management", {
				method: "DELETE",
				body: JSON.stringify({ user_id: managerId }),
				headers: { "Content-Type": "application/json" },
			});
			if (!res.ok) {
				throw new FetchError(res);
			}
		},
		onSuccess: (_, { managerId }) => {
			queryClient.setQueryData(currentUserQuery.queryKey, (user) => {
				if (!user) return user;
				return {
					...user,
					gived_managements: user.gived_managements.filter(
						(manager) => manager.manager_id !== managerId,
					),
				};
			});
		},
	});
}

export function useRefuseManagementPermissionsMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (options: { managementId: string }) => {
			const res = await fetch(
				`/api/users/management/self/${options.managementId}`,
				{
					method: "DELETE",
				},
			);
			if (!res.ok) {
				throw new FetchError(res);
			}
		},
		onSuccess: async (_, { managementId }) => {
			const me = await queryClient.ensureQueryData(currentUserQuery);

			const removedManagement = me.recieved_managements.find(
				(management) => management.id === managementId,
			);
			if (!removedManagement) {
				console.warn("Unrechable. Can't found get target management to refuse");
				return;
			}

			queryClient.setQueryData(currentUserQuery.queryKey, (user) => {
				if (!user) return user;
				return {
					...user,
					recieved_managements: user.recieved_managements.filter(
						(management) => management.id !== managementId,
					),
				};
			});

			queryClient.setQueryData(myServersQuery.queryKey, (servers) => {
				if (!servers) return servers;
				return {
					...servers,
					management: servers.management.filter(
						(server) => server.user_id !== removedManagement.owner_id,
					),
				};
			});
		},
	});
}

export const publicUserByIdQuery = (userId: string) =>
	queryOptions({
		queryKey: ["api", "users", "public", userId],
		queryFn: async ({ signal }) => {
			const res = await fetch(`/api/users/public/${userId}`, { signal });
			if (!res.ok) {
				throw new FetchError(res);
			}
			const { data: user } = (await res.json()) as { data: PublicUser };
			return user;
		},
	});

export const publicUserByUsernameQuery = (discordUsername: string) =>
	queryOptions({
		queryKey: ["api", "users", "public", "discord", discordUsername],
		queryFn: async ({ signal }) => {
			const res = await fetch(`/api/users/public/discord/${discordUsername}`, {
				signal,
			});
			if (!res.ok && res.status === 404) {
				return null;
			}
			if (!res.ok) {
				throw new FetchError(res);
			}
			const { data: user } = (await res.json()) as { data: PublicUser };
			return user;
		},
	});

export const userQuery = (userId: string) =>
	queryOptions({
		queryKey: ["api", "admin", "users", "details", userId],
		queryFn: async ({ signal }) => {
			const res = await fetch(`/api/admin/users/${userId}`, { signal });
			if (!res.ok) {
				throw new FetchError(res);
			}
			const { data: user } = (await res.json()) as {
				data: UserWithOwnedServers;
			};
			return user;
		},
	});

export type GetUsersQueryOptions = {
	tiers_ids?: string[];
	patreon_username?: string;
	discord_username?: string;
	page?: number;
	limit?: number;
	created_at?: "asc" | "desc";
};

export const usersQuery = (query?: GetUsersQueryOptions) =>
	queryOptions({
		queryKey: query
			? ["api", "admin", "users", "list", query]
			: ["api", "admin", "users", "list"],
		queryFn: async ({ signal }) => {
			const search = query ? createURLSearchParams(query).toString() : null;
			const res = await fetch("/api/admin/users?" + search, {
				signal,
			});
			if (!res.ok) {
				throw new FetchError(res);
			}
			return (await res.json()) as UsersPagingObject;
		},
		staleTime: Infinity,
	});

export function useToggleUserBanStatusMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (options: { userId: string; banReason?: string }) => {
			const res = await fetch(`/api/admin/users/${options.userId}/ban`, {
				method: "PATCH",
				...(options.banReason
					? {
							body: JSON.stringify({ banReason: options.banReason }),
							headers: { "Content-Type": "application/json" },
						}
					: undefined),
			});
			if (!res.ok) {
				throw new FetchError(res);
			}
			const { data: user } = (await res.json()) as {
				data: UserWithOwnedServers;
			};
			return user;
		},
		onSuccess: async (user) => {
			queryClient.setQueryData(userQuery(user.id).queryKey, user);
			queryClient.invalidateQueries(
				publicUserByUsernameQuery(user.discord.username),
			);
			queryClient.invalidateQueries(publicUserByIdQuery(user.id));
			queryClient.resetQueries(usersQuery());
			queryClient.resetQueries(supervisedServersQuery());
		},
	});
}

export function useForceTierMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (options: { userId: string; tierId: string | null }) => {
			const res = await fetch(`/api/admin/users/${options.userId}/tier`, {
				method: "PUT",
				body: JSON.stringify({ tier_id: options.tierId }),
				headers: { "Content-Type": "application/json" },
			});
			if (!res.ok) {
				throw new FetchError(res);
			}
			const { data: user } = (await res.json()) as {
				data: UserWithOwnedServers;
			};
			return user;
		},
		onSuccess: async (user) => {
			queryClient.setQueryData(userQuery(user.id).queryKey, user);

			const me = await queryClient.ensureQueryData(currentUserQuery);
			if (me.id === user.id)
				queryClient.setQueryData(currentUserQuery.queryKey, (me) => {
					if (!me) return me;
					return {
						...me,
						active_tier: user.active_tier,
						owned_tier: user.owned_tier,
						is_tier_forced: user.is_tier_forced,
						benefits: user.benefits,
					};
				});

			queryClient.resetQueries(usersQuery());
		},
	});
}

export interface UpdateUserBody {
	max_masterlist_tokens?: number | undefined;
	max_managers?: number | undefined;
	max_masterlist_groups?: number | undefined;
}

export function useUpdateUserMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (options: { userId: string; body: UpdateUserBody }) => {
			const res = await fetch(`/api/admin/users/${options.userId}`, {
				method: "PATCH",
				body: JSON.stringify(options.body),
				headers: { "Content-Type": "application/json" },
			});
			if (!res.ok) {
				throw new FetchError(res);
			}
			const { data: user } = (await res.json()) as {
				data: UserWithOwnedServers;
			};
			return user;
		},
		onSuccess: async (_, { body, userId }) => {
			queryClient.setQueryData(userQuery(userId).queryKey, (user) => {
				if (!user) return user;
				return {
					...user,
					max_masterlist_tokens:
						body.max_masterlist_tokens ?? user.max_masterlist_tokens,
					max_managers: body.max_managers ?? user.max_managers,
					max_masterlist_groups:
						body.max_masterlist_groups ?? user.max_masterlist_groups,
				};
			});

			const me = await queryClient.ensureQueryData(currentUserQuery);
			if (me.id === userId) {
				queryClient.setQueryData(currentUserQuery.queryKey, (user) => {
					if (!user) return user;
					return {
						...user,
						max_masterlist_tokens:
							body.max_masterlist_tokens ?? user.max_masterlist_tokens,
						max_managers: body.max_managers ?? user.max_managers,
						max_masterlist_groups:
							body.max_masterlist_groups ?? user.max_masterlist_groups,
					};
				});
			}
		},
	});
}
